.resource__image {
    display: block;

    img {
        width: 100%;
    }
}

#resources {
    main {
        h2 {
            @extend .h3;
            margin: 0.5rem 0 0.6em 0;
        }

        h3 {
            margin: 0 0 5px 0;
        }

        .sidebar {
            flex-basis: 30%;
            margin: 3rem 0;
            padding-top: 2rem;
            @include media(md) {
                width: 100%;
            }

            .tags {
                flex-direction: column;
            }
        }

        .container {
            align-items: flex-start;
            gap: 2rem;
            @include media(md) {
                flex-direction: column;
            }
        }

        .listing {
            flex-basis: 70%;
            margin: 3rem 0;
            @include media(md) {
                flex-basis: 100%;
            }
            .tiles {
                .tile {
                    &__content {
                        .subtext {
                            color: colour(red);
                        }
                    }
                }
            }
        }

        .subtext {
            font-size: 0.875rem;
        }

        .tiles {
            border-bottom: 1px solid black;
            margin-bottom: 2rem;
            padding-bottom: 1rem;
        }

        .tile {
            min-width: unset;
        }
        .tags {
            position: relative;
            align-items: flex-start;
        }

        .filters {
            flex-wrap: wrap;

            .tag {
                position: relative;
                background: colour(teal);
                cursor: pointer;

                img {
                    margin-left: 0.5rem;
                }
            }

            .tag__label {
                flex-basis: 100%;
                color: colour(black);
                font-size: 1rem;
                letter-spacing: 2px;
                padding: 0;
            }
        }

        .categories {
            position: relative;
            display: flex;
            flex-direction: column;

            .category {
                position: relative;
                background: colour(dark-grey) center center no-repeat;
                background-size: cover;
                color: colour(white);
                font-family: $font-header;
                font-size: 1.125rem;
                padding: 1rem;
                margin: 0.5rem 0;
                z-index: 10;
                transition: color 200ms ease;

                &::after {
                    content: '';
                    position: absolute;
                    background: linear-gradient(90deg, rgba(82, 82, 82, 1) 30%, rgba(82, 82, 82, 0) 100%);
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: -1;
                }

                &:hover {
                    color: colour(teal);
                }

                &.active {
                    background-color: colour(teal);

                    &::after {
                        background: linear-gradient(90deg, rgba(225, 87, 68, 1) 30%, rgba(225, 87, 68, 0) 100%);
                    }
                }
            }
        }

        .side_loading {
            position: absolute;
            visibility: hidden;
            opacity: 0;
            bottom: 0;
            top: 0;
            right: 0;
            left: 0;
            z-index: 15;
        }

        .categories.loading,
        .tags.loading {
            opacity: 0.35;

            .side_loading {
                visibility: visible;
                opacity: 1;
            }
        }

        .hero {
            padding: 4rem 0;

            &.hero {
                &__default {
                    .hero {
                        &__content {
                            h1 {
                                font-size: 3.75rem;
                                font-weight: 400;
                                line-height: 1.09;
                            }
                        }
                    }
                }
            }
        }
    }
}

.resource-filter {
    position: relative;

    &:focus-within {
        outline: 2px dashed colour(teal);
        outline-offset: 2px;
    }

    & + & {
        margin-top: 0.75em;
    }

    label {
        background: colour(red);
        color:colour(black);
        font-family: $font-noto;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        font-size: 0.875rem;
        padding: 0.75rem 1.5rem;
        letter-spacing: 0;
        line-height: 1;
        transition: 200ms background-color ease;
        cursor: pointer;

        &:hover {
            background-color: colour(teal);
            color: colour(white);
        }
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        opacity: 0;
        pointer-events: none;
    }

    input:checked ~ label {
        background-color: colour(teal);
        color: colour(white);
    }
}

.resource-sport-filter {
    &:focus-within {
        outline: 2px dashed colour(teal);
        outline-offset: 2px;
    }

    & + & {
        margin-top: 0.5em;
    }

    label {
        position: relative;
        background-color: #525252;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        color: #fff;
        font-family: Gilroy, sans-serif;
        font-weight: 600;
        font-size: 1.125rem;
        padding: 1rem;
        z-index: 10;
        letter-spacing: 0;
        line-height: 1;
        transition: color 200ms ease;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            background: linear-gradient(90deg, #525252 30%, rgba(82, 82, 82, 0) 100%);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
        }

        &:hover {
            color: colour(teal);
        }
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        opacity: 0;
        pointer-events: none;
    }

    input:checked ~ label {
        color: colour(teal);
    }
}

#resource-entry {
    main {
        section {
            &:first-of-type {
                margin: 2rem 0;

                @include media(sm) {
                    margin: 1rem 0;
                }
            }
        }

        .container {
            align-items: flex-start;
            gap: 3.75rem;
            @include media(md) {
                flex-direction: column;
                gap: 1.875rem;
            }

            .container {
                padding-left: 0;
                padding-right: 0;
            }
            .richtext-block {
                a {
                    text-decoration: underline;
                }
            }
        }

        .content {
            margin-top: 3rem;
            section {
                &:nth-of-type(1) {
                    margin-top: 0;
                }
            }
        }

        .hero {

            .breadcrumbs {
                margin-bottom: 30px;
            }
            .hero {
                &__content {

                    .subtext {
                        font-family: $font-noto;
                        font-size: 1.125rem;
                        font-weight: 600;
                        line-height: 1.9;
                        color: colour(red);
                        text-transform: uppercase;
                        margin-bottom: 0;
                    }

                    h1 {
                        @extend .h2;
                        font-size: 3.75rem;
                        font-weight: 400;
                        line-height: 1.09;
                        text-align: left;
                        margin-top: 0.625rem;
                    }

                    .date {
                        font-family: $font-noto;
                        font-size: 0.875rem;
                        font-weight: 600;
                        line-height: 1.2;
                        letter-spacing: 0.05em;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    .tags__container {
        margin: 1.875rem 0;
        .tags {
            .tag {
                background: colour(red);
                padding-top: 10px;
                padding-bottom: 10px;
                a {
                    font-family: $font-noto;
                    font-size: 0.75rem;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                }

                &__label {
                    text-transform: uppercase;
                }
                &:hover {
                    background: colour(theme-blue);
                }
            }
        }
    }

    .sidebar {
        flex-basis: 30%;
        padding: 0;
        @include media(md) {
            flex-basis: 100%;
        }

        h2 {
            @extend .h3;

            font-family: $font-main;
            font-weight: 600;
            line-height: 1.2;
            letter-spacing: 1px;
            color: colour(black);
            text-transform: uppercase;
            margin-bottom: 15px;
        }

        .tiles > * + * {
            margin-top: 1.5rem;
        }

        .tiles > .tile {

            .tile {
                &__content {
                    margin-top: 1.25rem;;

                    h3 {
                        font-size: 1.25rem;
                        font-weight: 600;
                        letter-spacing: 1px;
                        margin-bottom: 10px;
                    }
                }
            }

            @include media(sm) {
                min-width: 100%;
            }
        }
    }
}
