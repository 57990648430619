.notification-bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0;
    color: white;
    text-align: center;

    &.success {
        background: #418841;
        animation: reveal 8s;
    }

    &.error {
        background: #c9302c;
        animation: reveal 8s;
    }

    &.hidden {
        display: none;
    }
}

@keyframes reveal {
    0% {
        height: 0;
    }

    10% {
        height: 3rem;
    }

    90% {
        height: 3rem;
    }

    100% {
        height: 0;
    }
}
