:root {
    --hero-square-size: 400px;
    @include media(sm) {
        --hero-square-size: 185px;
    }
}

.hero {
    @extend .bg__textured--teal;
    position: relative;
    color: colour(white);
    margin: 0;
    text-align: center;
    overflow: hidden;

    &__overlay {
        background: $texture center center no-repeat;
        background-size: cover;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0px;
        left: 0px;
        opacity: 1; // For v2 Hero bg
        z-index: 1;
    }

    &__video {
        @include center-content;
        position: absolute;
        min-width: 100%;
        min-height: 102%;
        width: auto;
        height: auto;
        z-index: -1;
    }

    &__content {
        padding: 25px;

        &-box {
            border: 2px dashed colour(light_cream);
            z-index: 1;
            position: relative;
            padding-top: 47px;
            padding-bottom: 101px;
        }
        h1 {
            margin-bottom: 18px;
            line-height: 1.5;
            font-weight: 400;
            font-size: clamp(40px, 5vw + 1rem, 130px);
        }

        &.sr-only {
            padding: 0;
        }
    }

    .container {
        position: unset;
    }

    &__default {
        .breadcrumbs {
            text-align: center;
        }

        h1 {
            @extend .h2;
            color: inherit;
            margin: 0;
        }

        h1 + p {
            margin: 0.625rem 0 0.5rem 0;
        }

        .hero__content {
            padding: 3.75rem 0 3.75rem 0;
            max-width: 60rem;
            margin: auto;
            text-align: center;

            p {
                font-family: $font-main;
                font-size: 1.25rem;
                line-height: 1.3;
            }

            .date {
                color: colour(dark-grey);
                font-family: $font-alt;
                font-size: 1rem;
                letter-spacing: 2px;
            }

            .subtext {
                font-family: $font-handwritten;
                font-size: 1.125rem;
                margin: 0.5rem 0 0 0;
                color: colour(cream);
                margin-bottom: 1.25rem;
                text-transform: unset;
            }
        }

        .breadcrumbs {
            color: white;

            a {
                color: white;
            }

            .active {
                color: colour(cream);
            }
        }
    }

    &__simple {
        background: transparent;
        color: colour(black);
        padding: 1.875rem 0 0;

        .breadcrumbs {
            color: black;

            a {
                color: black;
            }

            .active {
                color: colour(slate-gray);
            }
        }

        .subtext {
            @extend .h4;
            font-family: $font-handwritten;
            text-transform: capitalize;
            color: black;
        }

        .hero__content {
            padding: 0;

            &.text__align--left {
                max-width: none;
            }

            h1 {
                color: colour(teal);
                font-size: clamp(50px, 5vw + 1rem, 71px);
                font-weight: 400;
                line-height: 67.45px;
                letter-spacing: 1px;
                text-align: center;
                margin-top: 21px;

                @include media(sm) {
                    font-size: clamp-sizes(2.5, 4.375);
                }
            }

            .plain-link {
                font-family: $font-main;
                font-size: 22px;
                font-weight: 400;
                line-height: 26px;
                text-align: center;
                color: black;
                text-transform: capitalize;
            }
        }
    }

    &__large {
        padding-top: 65px;
        padding-bottom: 94px;
        h1 {
            font-size: clamp(30px, 5vw + 1rem, 60px);
            font-weight: 400;
            line-height: 65px;
            color: white;
            margin-bottom: 25px;
        }

        .hero__content {
            padding: 0;
            max-width: 580px;
            width: 100%;

            &.text__align--left {
                margin-left: 0;
            }
        }

        p {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            color: white;
            font-family: $font-main;

            &.subtext {
                font-family: $font-handwritten;
                font-size: clamp(12px, 3vw + 1rem, 20px);
                font-weight: 600;
                line-height: 25px;
                letter-spacing: 1px;
                color: colour(red);
                text-transform: uppercase;
                margin-bottom: 8px;
            }
        }

        .statBar {
            padding-bottom: 0;

            h2 {
                color: white;
            }

            p {
                font-family: $font-noto;
                font-size: 18px;
                font-weight: 600;
                line-height: 30px;
                color: white;
            }

            @include media(md) {
                padding: 0;
            }
        }

        .imageWithText {
            padding: 0;
            margin: 0;

            &.image--right {
                .image__decorated {
                    margin-right: 31px;
                }
            }
        }

        .image__decorated::before {
            z-index: 0;
        }
    }

    &--homepage {
        .hero__overlay {
            background: url('/assets/img/content/bg-homepage-hero-v2.jpg')
                center center no-repeat; // For v2 Hero bg
            background-size: cover;
        }

        .hero__content {
            .subhead {
                font-family: $font-alt;
                text-transform: uppercase;
                // font-size: 27px;
                font-size: clamp(16px, 3vw + 0.5rem, 22px);
                font-weight: 600;
                line-height: 1.2;
                color: colour(light_cream);
                fill: colour(light_cream);
                letter-spacing: 0.09em;
                text-align: center;
            }

            p {
                font-family: $font-alt;
                text-transform: uppercase;
                font-size: clamp(12px, 4vw + 0.5rem, 42px);
                font-weight: 500;
                line-height: 50.4px;
                letter-spacing: 0.09em;
                color: colour(light_cream);
            }

            .container {
                position: relative;
                z-index: 2;
            }

            // for v2 design
            .hero__subhead {
                position: relative;
                height: 130px;
                margin-bottom: 22px;

                &:before {
                    content: '';
                    background: url('/assets/img/accents/hero-tag-bg.svg')
                        center center no-repeat; // For v2 Hero tag line
                    display: block;
                    position: absolute;
                    inset: 0;
                    background-size: contain;
                    top: 30px;
                    bottom: -10px;
                    background-position: center;
                }

                svg {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    z-index: 1;
                }
            }
        }
    }
}

// Banner svg media query for smaller screens
@media screen and (max-width: 530px) {
    .hero--homepage .hero__content .hero__subhead {
        height: 100px;

        &:before {
            background-size: 100% 100%;
        }

        svg {
            transform: scale(0.9);
            transform-origin: center center;
            top: 5px;
        }
    }
}

//------------------------------------
// Site Humphrys
//------------------------------------

.site-humphrys {
    .hero {
        &--homepage {
            padding: 0px;

            .hero__content {
                .subtext {
                    font-size: 48px;
                    line-height: 1;

                    @media screen and (max-width: 768px) {
                        font-size: 36px;
                    }

                    @media screen and (max-width: 480px) {
                        font-size: 24px;
                    }
                }
            }

            .hero__overlay {
                background: linear-gradient(
                        0deg,
                        rgba(2, 0, 50, 0.7),
                        rgba(2, 0, 50, 0.7)
                    ),
                    url('/assets/img/content/humphrys/bg-homepage-hero.jpeg');
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        &--bg {
            padding: 0;

            .hero {
                &__overlay {
                    background: linear-gradient(
                            0deg,
                            rgba(2, 0, 50, 0.7),
                            rgba(2, 0, 50, 0.7)
                        ),
                        url('/assets/img/content/humphrys/bg-aboutpage-hero.png');
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                }

                &__content {
                    &-box {
                        padding-bottom: 150px;

                        @include media(md) {
                            padding: 100px 0;
                        }

                        &.no-extra-padding {
                            padding: 131px 0;
                        }

                        .imageWithText {
                            padding: 0;

                            &.image {
                                &--right {
                                    .responsive-image-wrapper {
                                        margin-left: auto;
                                    }
                                }
                                &--left {
                                    .responsive-image-wrapper {
                                        margin-right: auto;
                                    }
                                }
                            }

                            p {
                                &.subtext {
                                    font-family: $humphrys-font-content-cond !important;
                                    margin-bottom: 10px !important;
                                }
                            }
                        }

                        &.has-stat {
                            padding-bottom: 50px;

                            .imageWithText {
                                padding-bottom: 148px;

                                @include media(md) {
                                    padding-bottom: 0;
                                }
                            }

                            .columns {
                                p {
                                    color: white;
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__content {
            padding: 0;

            &-box {
                border: unset;
                padding: 131.5px 0 50px 0;

                @media screen and (max-width: 768px) {
                    padding: 65px 0 50px 0;
                }

                .container {
                    max-width: 1370px;

                    .button-humphrys__primary:after {
                        background-color: $humphrys-dark-blue !important;
                    }
                }
            }
        }

        &__subhead {
            height: auto;
            margin: 0;

            &:before {
                display: none;
            }

            h3 {
                font-size: 16px;
                line-height: 17px;
                font-weight: 700;
                text-transform: uppercase;
                color: black;
                margin-bottom: 16px;
                font-family: $humphrys-font-content;
            }
        }

        h1 {
            font-family: $humphrys-font-heading;
            font-weight: 600;
            font-size: 60px;
            line-height: 66px;
            margin-bottom: 16px;
            text-transform: unset;
            max-width: 1248px;
            color: white;
            margin-left: auto;
            margin-right: auto;

            @media screen and (max-width: 1024px) {
                font-size: 54px;
                line-height: 60px;
            }

            @media screen and (max-width: 768px) {
                font-size: 48px;
                line-height: 54px;
            }

            @media screen and (max-width: 480px) {
                font-size: 36px;
                line-height: 42px;
            }
        }

        &.bg-light {
            h1 {
                color: black;
            }
        }

        p {
            font-family: $humphrys-font-content;
            font-size: 20px;
            line-height: 28px;
            max-width: 700px;
            font-weight: 400;
            margin: 0 auto 32px;
            text-transform: unset;
            color: white;
            letter-spacing: normal;

            &.subtext {
                font-family: $humphrys-font-content-cond;
                margin-bottom: 10px;
            }

            @media screen and (max-width: 768px) {
                font-size: 16px;
                line-height: 24px;
            }
        }

        &__image {
            &-box {
                border: unset;
                padding: 151.5px 0 0 0;

                @media screen and (max-width: 768px) {
                    padding: 75px 0 0 0;
                }
            }
        }

        .header {
            &__image__section {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 50px;

                @media screen and (max-width: 1024px) {
                    gap: 20px;
                }

                @media screen and (max-width: 768px) {
                    flex-direction: column;
                }
            }

            &__inner__image__section {
                position: relative;
                width: 100%;
                min-height: 160px;
                a,
                a:visited {
                    position: inherit;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
                a:hover,
                a:focus {
                    .responsive-image-wrapper:before {
                        background: linear-gradient(
                            rgba(0, 0, 0, 0.3),
                            rgba(0, 0, 0, 0.3)
                        );
                    }
                }
                .responsive-image-wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    overflow: hidden;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(
                            rgba(0, 0, 0, 0.5),
                            rgba(0, 0, 0, 0.5)
                        );
                        background-size: cover;
                        z-index: 2;
                        transition: background-color 0.3s ease;
                    }

                    .responsive-image {
                        height: 100%;

                        img {
                            object-fit: cover;
                        }
                    }
                }
                p {
                    display: block;
                    position: relative;
                    z-index: 3;
                    font-size: 24px;
                    line-height: 40px;
                    font-weight: 500;
                    text-align: center;
                    margin-bottom: 0;
                    padding: 60px 40px;

                    @media screen and (max-width: 1024px) {
                        font-size: 20px;
                        line-height: 36px;
                    }

                    @media screen and (max-width: 480px) {
                        font-size: 16px;
                        line-height: 32px;
                    }
                }
            }
        }

        .first__section {
            background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
                url('/assets/img/content/humphrys/industrial-tarps.png');
            background-size: cover;
            background-position: center;
        }

        .second__section {
            background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
                url('/assets/img/content/humphrys/custom-covers.png');
            background-size: cover;
            background-position: center;
        }

        .third__section {
            background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
                url('/assets/img/content/humphrys/specialty-fabrics.png');
            background-size: cover;
            background-position: center;
        }

        &__overlay {
            background: $texture-humphrys-ivory center center repeat;
        }
    }

    &-header-hero-overlay {
        .hero {
            &__content {
                &-box {
                    padding-top: 271px;

                    @include media(md) {
                        padding-top: 170px;
                    }
                }
            }

            &__image {
                &-box {
                    &.desktop {
                        display: block;

                        @include media(md) {
                            display: none;
                        }
                    }

                    &.mobile {
                        display: none;
                        background: transparent;
                        padding-top: 30px;

                        @include media(md) {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
