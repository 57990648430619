.collage {
    .imageWithText__content {
        padding-bottom: 6rem;
        @include media(md) {
            padding-bottom: 0;
        }
    }

    .overlap {
        position: relative;
        display: flex;
        max-width: 85%;
        margin: -6rem auto 0;
        z-index: 10;

        @include media(md) {
            flex-wrap: wrap;
        }

        > * {
            position: relative;
            flex: 1 1 0;

            @include media(md) {
                flex-basis: 100%;
            }
        }

        &__image {
            max-width: 350px;

            @include media(md) {
                max-width: 100%;
            }

            .responsive-image-wrapper,
            .responsive-image {
                height: 100%;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        &__heading {
            font-family: $font-handwritten;
            font-size: 1.125rem;
            color: colour(cream);
            display: inline-block;
            text-transform: unset;
            margin-bottom: 20px;
            font-weight: 400;
        }

        &__text {
            font-size: 1.125rem;
            line-height: 1.3;
        }

        &__subTitle {
            letter-spacing: 2px;
        }

        &__content {
            background: $texture-teal center center no-repeat;
            background-size: cover;
            color: colour(white);
            padding: 2.5rem;

            h3 {
                margin-bottom: 0;
            }

            h3 + span {
                display: block;
                margin-bottom: 1.25rem;
            }

            .subtitle {
                font-family: $font-alt;
                font-size: 0.75rem;
                line-height: 0.875rem;
                text-transform: uppercase;
            }
        }
    }
}

.site-humphrys {
    .collage {
        .overlap {
            border-radius: 12px;
            overflow: hidden;

            &__content {
                background: $humphrys-dark-blue;
                color: #fff;

                h2 {
                    color: white;
                    font-size: 48px;
                    font-weight: 600;
                    line-height: 48px;
                    text-transform: none;
                    margin-bottom: 16px;
                }

                h3 {
                    font-family: $humphrys-font-content;
                    font-size: 1.5rem;
                    font-weight: 500;
                    line-height: 28px;
                    text-align: left;
                    text-transform: none;
                    margin: unset;
                    margin-top: 12px;
                    color: white;
                }

                .subtitle {
                    font-family: $humphrys-font-content;
                    font-size: 0.75rem;
                    line-height: 0.875rem;
                    text-transform: uppercase;
                    margin: 1rem 0;
                    color: white;
                }

                p {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                    font-family: $humphrys-font-poppins;
                    color: white;
                }
            }
        }
    }
}
