/* Import */

//Defaults
@import 'variables';
@import 'functions';
@import 'reset';

// NPM
@import '~swiper/swiper.scss';
@import '~swiper/modules/a11y.scss';
@import '~swiper/modules/navigation.scss';
@import '~swiper/modules/pagination.scss';
@import '~swiper/modules/effect-fade.scss';
// Mixins and Base
@import 'mixins';
@import 'typography';
@import 'utilities';
@import './base/base.scss';
@import './base/buttons.scss';
@import './base/inputs.scss';

// Global
@import './components/blocks/collage.scss';
@import './components/blocks/cta.scss';
@import './components/blocks/featuredHeroProducts.scss';
@import './components/blocks/featuredResources.scss';
@import './components/blocks/getQuote.scss';
@import './components/blocks/imageTiles.scss';
@import './components/blocks/imageWithText.scss';
@import './components/blocks/multiColumn.scss';
@import './components/blocks/products.scss';
@import './components/blocks/richtext.scss';
@import './components/blocks/statBar.scss';
@import './components/breadcrumbs.scss';
@import './components/cart.scss';
@import './components/columns.scss';
@import './components/discounts.scss';
@import './components/footer.scss';
@import './components/forms.scss';
@import './components/hero.scss';
@import './components/humphrys-logo.scss';
@import './components/icon-card.scss';
@import './components/modal.scss';
@import './components/nav.scss';
@import './components/notification-bar.scss';
@import './components/orders.scss';
@import './components/product-card.scss';
@import './components/resource-card.scss';
@import './components/responsive-image.scss';
@import './components/reviews.scss';
@import './components/slider.scss';
@import './components/table.scss';
@import './components/tabs.scss';
@import './components/tags.scss';
@import './components/tiles.scss';

/* Page Overrides */
@import './templates/commerce.scss';
@import './templates/home.scss';
@import './templates/my-account.scss';
@import './templates/product-listing.scss';
@import './templates/product.scss';
@import './templates/resources.scss';
@import './templates/search.scss';
