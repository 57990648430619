.tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .tag {
        background: colour(dark-grey);
        color: colour(white);
        font-family: $font-header;
        font-size: 0.875rem;
        margin: 0 1.5rem 0.75rem 0;
        padding: 0.75rem 1.5rem;
        transition: 200ms background-color ease;

        a {
            color: colour(black);

            &:hover {
                color: colour(white);
            }
        }

        &:hover,
        &.active {
            background: colour(teal);

            a {
                color: colour(white);
            }
        }

        &__label {
            font-family: $font-alt;
            font-size: 1.125rem;
            line-height: 1.375rem;
            font-weight: 700;
            margin: 0 0 0.75rem 0;
            padding: 0.75rem 1.5rem 0.75rem 0;
        }
    }
}

.site-humphrys {
    .tags {
        .tag {
            background: $humphrys-dark-blue;
            font-size: 16px;
            line-height: 16px;
            font-weight: 500;
            font-family: $humphrys-font-content;
            border-radius: 5px;
            padding: 13px 10px;
            text-transform: uppercase;
            align-items: center;
            display: flex;
            justify-content: space-between;

            @include media(lg) {
                font-size: 14px;
                line-height: 16px;
            }

            &__label {
                width: 100%;
                font-size: 16px;
                line-height: 16px;
                font-weight: 400;
                font-family: $humphrys-font-content;
                margin-bottom: 20px;

                &.subtext {
                    font-size: 16px;
                    line-height: 16px;
                    font-weight: 400;
                    font-family: $humphrys-font-content;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
