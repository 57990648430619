.richtext-block {
    figure {
        display: inline-block;
        position: relative;
        margin-bottom: 1.875rem;

        img {
            width: 100%;
        }

        figcaption {
            margin: 0.625rem 0;
            font-size: 1.2rem;
            font-family: $font-handwritten;
        }
    }
}

.site-humphrys {
    .richtext-block {
        h2 {
            font-size: 48px;
            line-height: 48px;
            color: black;
            font-weight: 600;
            font-family: $humphrys-font-heading;
            text-transform: unset;
            margin-bottom: 15px;
        }

        p,
        blockquote,
        li {
            font-family: $humphrys-font-poppins;
            font-size: 16px;
            line-height: 22px;
            color: black;
            font-weight: 400;
        }

        ul {
            li {
                font-family: $humphrys-font-content;
            }
        }

        blockquote {
            background-color: white;
            font-family: $humphrys-font-content;

            &:after {
                background: url(/assets/img/icons/quote-dark-blue.svg);
            }
        }
    }
}
