@use 'sass:math';

.cta {
    button {
        @include media(sm) {
            margin: 0 auto;
            width: 100%;
        }
    }

    .embed-container {
        padding-bottom: 0;
        height: unset;
        width: 100%;
        filter: drop-shadow(0px 7px 13px rgba(0, 0, 0, 0.15));

        &::before {
            display: block;
            content: '';
            width: 100%;
            padding-top: math.div(9, 16) * 100%;
        }

        iframe,
        object,
        embed {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    .imageWithText {
        padding: 0;

        @include media(md) {
            padding: 0.5rem 1.5rem;
        }
    }

    &__home {
        .cta__text {
            margin-bottom: 1.25rem;
        }

        .imageWithText {
            h2 {
                @extend .h1;
                // Justin: This rule is causing overflow issues. I'm setting it back to 100%. 
                //width: 130%;
                width: 100%;
                position: relative;
                z-index: 1;

                @include media(lg) {
                    width: auto;
                }
            }

            p {
                font-size: 1rem;
            }

            :nth-child(1n) {
                flex: 1;
            }
            :nth-child(2n) {
                flex: 2;
                @include media(lg) {
                    flex: auto;
                }
            }
        }
    }
}
