@use 'sass:math';

//------------------------------------
// General
//------------------------------------
html {
    font-size: 16px; // This establishes what 1rem is
}

html,
body {
    height: 100%;
}

body {
    color: $plain-text;
    font-family: $font-main;
    min-width: 320px;
    overflow-x: hidden;
    position: relative;
    width: 100%;

    // For v2 design
    main {
        position: relative;
        background-color: colour(light_cream);

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: $texture-cream center repeat;
            opacity: 0.2;
            z-index: 0;
        }
    }

    &.site-humphrys {
        font-family: $humphrys-font-content;

        main {
            background: unset;
            background-color: colour(light-grey);

            &::before {
                display: none;
            }
        }

        section {
            margin: 7rem 0;

            @include media(md) {
                margin: 4rem 0;
            }
        }
    }

    .no-margin {
        margin: 0 !important;
    }

    .no-padding {
        padding: 0 !important;
    }

    .mr-0 {
        margin-right: 0;
    }
}

a {
    position: relative;
    color: $link;
    font-weight: 600;

    &:hover {
        color: $link-hover;
        transition: 0.3s ease all;
    }
}

aside {
    position: relative;
    padding: 0 0 3rem 0;
}

button {
    position: relative;
}

figure {
    margin: 2rem 0;
}

hr {
    border: none;
    border-bottom: 1px solid black;
    margin: 1.5rem 0;
}

main {
    overflow: hidden;
}

section {
    margin: 7.5rem 0;

    @include media(md) {
        margin: 4rem 0;
    }
}

//------------------------------------
// Containers
//------------------------------------
.container {
    @include container;

    .w-full {
        width: 100%;
    }

    &.-flex,
    .-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &.-justify-end {
        justify-content: flex-end;
    }

    &.-static {
        position: static;
    }

    &__commerce {
        display: grid;
        grid-template-columns: 60% 40%;
        gap: 2rem;

        @include media(md) {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    &__xs {
        max-width: 460px;
    }

    &__s {
        max-width: 565px;
    }

    &__md {
        max-width: 735px;
    }
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    > p {
        @extend .sr-only;
    }

    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    margin: 2rem 0;

    &__next {
        margin-left: auto !important;
    }

    &__number {
        color: black;
        font-family: $font-alt;
        flex: 1;
        text-align: center;
    }
}

.badge {
    display: inline-block;
    position: relative;
    border-radius: 50%;
    background: colour(teal);
    font-family: $font-alt;
    color: colour(white);
    padding: 0.5rem;
    margin-top: 0.2rem;
    margin-left: 1rem;
    height: 24px;
    width: 24px;

    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: math.div(1, 1) * 100%;
    }

    span {
        @include center-content;
    }
}

//------------------------------------
// Error Handling
//------------------------------------

.no-results {
    background: colour(light-grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.375rem;
    padding: 1.875rem;

    svg,
    p {
        position: relative;
    }

    p {
        font-size: 1.125rem;
        line-height: 1.625rem;
        margin: 0;
        text-align: center;
    }
}

//------------------------------------
// Accessibility
//------------------------------------
.skip-to-main {
    padding: 1rem;
    background: colour(white);
    color: colour(teal);
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 100;
    transform: var(--skip-to-main__transform, translateX(-1000px));
}

#commerce .skip-to-main {
    background-color: colour(teal);
    color: white;
}

.skip-to-main:focus,
.skip-to-main:active {
    --skip-to-main__transform: none;
}

%dashed-focus {
    outline: 2px dashed colour(teal);
    outline-offset: 3px;
}

:focus {
    @extend %dashed-focus;
}

.white-focus:focus {
    @extend %dashed-focus;
    outline-color: colour(light_cream);
}

[data-docus-suppressed='true'] {
    outline: none !important;
}

.site-humphrys {
    &.site-humphrys-header-hero-overlay {
        .skip-to-main {
            &:focus {
                outline-color: white;
            }
        }
    }
}
