.products {
    .subtext {
        color: colour(black);
        font-family: $font-alt;
        letter-spacing: 2px;
        font-size: 0.875rem;
    }

    .button {
        margin-top: 0 !important;
    }

    .product {
        &__simple {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            margin: auto 0 2rem 0;

            div:nth-child(1) {
                grid-area: 1 / 1 / 2 / 2;
            }

            div:nth-child(2) {
                grid-area: 1 / 2 / 2 / 3;
            }

            a {
                text-decoration: underline;
            }
        }

        &__full {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            border-top: 1px solid black;
            font-family: $font-alt;
            letter-spacing: 2px;
            margin: 0.5rem 0 2rem 0;
            padding: 0.5rem 0;

            .product__full-reviews,
            .product__full-price {
                display: flex;
                flex-direction: column;
                gap: 0.25rem;
            }

            .product__full-reviews {
                grid-column: 1;
                color: #9aa4ad;
                font-size: 0.75rem;
            }

            .product__full-price {
                grid-column: 2;
                text-align: right;
            }

            .products__full-price-heading {
                font-size: 0.75rem;
            }
        }
    }
}

.site-humphrys {
    .products {
        .subtext {
            color: colour(black);
            font-family: $humphrys-font-content;
            font-size: 1rem;
        }

        .product {
            &__full {
                display: flex;
                border-top: unset;
                font-family: $humphrys-font-content;
                letter-spacing: 2px;
                margin: unset;
                margin-top: 0.75rem;
                padding: unset;

                .product__full-price {
                    text-align: left;
                    gap: 0.5rem;

                    .products__full-price-heading {
                        font-family: $humphrys-font-content;
                        font-size: 0.875rem;
                        font-weight: 400;
                        line-height: 1rem;
                    }

                    .product__price-value {
                        font-family: $humphrys-font-content;
                        font-size: 0.875rem;
                        font-weight: 500;
                        line-height: 1rem;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}
