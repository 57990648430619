footer.primary {
    background: colour(black);
    color: colour(white);

    a {
        color: colour(white);
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;

        &:hover {
            color: colour(red);
        }
    }

    input,
    button {
        &:focus {
            outline-color: colour(light_cream);
        }
    }

    nav {
        display: flex;
        justify-content: center;
        gap: 5rem;

        @include media(md) {
            gap: 4rem;
        }

        @include media(sm) {
            flex-direction: column;
            gap: 2rem;
        }
    }

    ul {
        li {
            margin: 0.75rem 0;
        }
    }

    h2 {
        text-transform: uppercase;
        font-weight: 600;
        line-height: 35px;
        font-size: clamp(20px, 5vw + 1rem, 30px);
        color: colour(white);
        margin-bottom: 0.625rem;
        font-family: $font-handwritten;
    }

    .main {
        padding: 2rem 0;
    }

    .-flex {
        align-items: flex-start;
        gap: 1.25rem;

        @include media(md) {
            align-items: center;
            flex-direction: column;
            gap: 2rem;
        }
    }

    .company__info {
        flex: 1;

        p {
            font-size: 1.1rem;
        }

        .logo {
            display: block;
            margin-bottom: 1rem;
            @include media(md) {
                text-align: center;
            }

            svg {
                height: 85px;
            }
        }

        .logo--division-of-humphrys svg {
            max-width: 10rem;
        }
    }

    .footer__nav {
        flex: 3;
        @include media(md) {
            text-align: center;
        }
    }

    .footer__contact {
        flex: 1.5;

        p {
            font-size: 1.1rem;
        }

        .form__group {
            position: relative;
        }

        .button {
            color: colour(cream);
            display: block;
            margin: 0;
            background-color: transparent;
            padding: 0;
            box-shadow: none;
            text-align: right;

            &::before {
                content: none;
            }
            &::after {
                content: none;
            }
        }
    }

    .footer__email {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }

    .subfooter {
        border-top: 1px solid #2d2b2b;
        padding: 1rem 0;

        .container {
            align-items: center;
            > * {
                flex: 1;
            }
        }

        .company {
            &__phone-fax {
                a {
                    display: flex;
                    align-items: center;
                    font-size: 1.125rem;
                    line-height: 1.625rem;
                }
                // These span contains the icons:
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 2rem;
                }
                svg {
                    width: 1.5625rem;
                    height: auto;
                    padding-right: 0.5rem;
                }
                // Second SVG (fax number):
                a.fax svg {
                    width: 1.8rem;
                }
            }

            &__legal {
                ul {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    li {
                        padding: 0 1rem;
                    }
                }
            }

            &__copyright {
                font-size: 0.9rem;
                line-height: 1.5;
                font-weight: 500;
                text-align: right;
            }
        }
    }
}

//------------------------------------
// Site Humphrys
//------------------------------------

.site-humphrys {
    footer.primary {
        background-color: colour(humphrys-footer-blue);

        a {
            &:hover {
                color: $humphrys-yellow;
            }
        }

        nav {
            @media only screen and (max-width: 991px) {
                gap: 1rem;
            }
        }

        .company {
            &__info {
                .logo {
                    display: flex; // Remove white space artifact
                    margin: 0 auto 15px;
                    max-width: 160px;
                    aspect-ratio: 1/1;

                    svg {
                        height: 100%;
                        width: auto;
                    }
                }

                &__text {
                    p {
                        font-family: $humphrys-font-content;
                        font-size: 18px;
                        line-height: 24px;
                        font-weight: 400;
                        color: white;
                        text-align: center;
                    }
                }
            }

            &__phone-fax {
                a {
                    font-family: $humphrys-font-content;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    padding: 4px;
                }
            }

            &__legal {
                ul {
                    li {
                        margin: 0;
                        a {
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 500;
                            color: white;

                            &:hover {
                                color: $humphrys-yellow;
                            }
                        }
                    }
                }
            }

            &__copyright {
                font-family: $humphrys-font-content;
                font-size: 14px;
                line-height: 28px;
                font-weight: 500;
                color: white;
            }
        }

        h2 {
            font-family: $humphrys-font-heading;
            font-size: 20px;
            line-height: 22px;
            margin-bottom: 25px;
            font-weight: 600;

            @media only screen and (max-width: 768px) {
                margin-bottom: 10px;
            }
        }

        ul {
            li {
                margin-top: 0;
                margin-bottom: 13px;

                a {
                    font-family: $humphrys-font-content;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }

        .footer__contact {
            h2 {
                margin-bottom: 8px;
            }

            p {
                font-family: $humphrys-font-content;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 15px;
            }

            .fui-field-container {
                .fui {
                    &-input {
                        padding: 20px;
                        font-size: 14px;
                        line-height: 16px;
                        font-family: $humphrys-font-content;
                        margin-bottom: 8px;
                        margin-top: 5px;
                        margin-right: 0;
                    }

                    &-label,
                    &-required {
                        color: white;
                        font-size: 1rem;
                    }
                }
            }
        }

        .subfooter {
            padding: 23px 0;
        }
    }
}
