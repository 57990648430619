/* Typography */
h1,
h2,
h3,
h4,
h5,
legend {
    font-family: $font-header;
    font-weight: normal;
    line-height: 1;
    margin: 0;
    text-transform: uppercase;

    span,
    strong {
        @include colour-line;
    }
}

.jumbo {
    font-size: clamp-sizes(5.625, 8.75);
    font-weight: 700;
    line-height: 0.8;
}

h1,
.h1 {
    font-size: clamp-sizes(5.625, 8.75);
    font-weight: 700;
}

h2,
.h2 {
    font-size: clamp-sizes(2.5, 4.375);
    font-weight: 700;
    color: colour(teal);
    line-height: 0.9;
}

h3,
.h3 {
    font-size: clamp-sizes(1.65, 1.875);
    font-weight: 400;
    text-transform: unset;
}

h4,
.h4 {
    text-transform: unset;
    font-size: clamp-sizes(1.25, 1.5);
    font-weight: 400;
}

h5,
.h5 {
    text-transform: unset;
    font-size: clamp-sizes(1, 1.25);
    font-weight: 400;
}

p {
    font-family: $font-main;
    font-size: 1rem;
    line-height: 1.25rem;
    padding: 0;

    &:not(:last-child) {
        margin: 0 0 1em 0;
    }

    a {
        font-weight: initial;
    }
}

b,
strong {
    font-weight: 700;
}

i,
em {
    font-style: italic;
}

blockquote {
    background: colour(light-grey);
    padding: 2rem 2rem;
    position: relative;
    line-height: 1.625rem;
    font-size: 1rem;
    font-weight: 400;
    margin: 3rem 0;

    &::after {
        content: '';
        background: url(/assets/img/icons/quote.svg);
        position: absolute;
        top: -15px;
        left: 38px;
        height: 32px;
        width: 56px;
    }
}

legend {
    font-size: clamp-sizes(1.65, 1.875);
    font-weight: 700;
}

.heading {
    font-family: $font-alt;
    font-size: clamp-sizes(0.75, 0.75);
    line-height: 1.19rem;
}

.subtext {
    display: block;
    color: colour(teal);
    font-family: $font-header;
    margin: 1rem 0 0.5rem 0;
    text-transform: uppercase;
}

.plain-link {
    color: inherit;
    font-weight: 400;
    text-decoration: underline;
    transition: color 200ms ease;

    &:hover {
        color: colour(teal);
    }
}

.arrow-link {
    @include colour-line;
    display: inline-block;
    color: inherit;
    font-weight: 700;
    font-family: $font-alt;
    text-transform: uppercase;

    &:hover {
        .arrow-link__arrow {
            transform: translateX(5px);
        }
    }

    &__arrow {
        transition: transform 150ms ease;
    }
}

//------------------------------------
// Site Humphrys
//------------------------------------

.site-humphrys {
    h1, .hero__content h1 {
        font-family: $humphrys-font-heading;
        font-weight: 700;
    }
    a {
        color: $link;
        
        &:hover {
            color: $link-hover;
        }
    }

    h2, h3, h4, h5, h6 {
        font-family: $humphrys-font-heading;
        font-weight: 600;
        color: colour(black);
    }
    h2 {
        font-size: 3rem;
        line-height: 1;

        @include media(md) {
            font-size: 36px;
            line-height: 40px;
        }
    }

    .bg-dark {
        p {
            a {
                color: $humphrys-yellow;

                &:hover {
                    color: $humphrys-ivory;
                }
            }
        }
    }

    p {
        a {
            color: $humphrys-yellow;

            &:hover {
                color: $humphrys-dark-blue;
            }
        }
    }
}

//------------------------------------
// Links
//------------------------------------
.link {
    &__simple {
        color: colour(black);
        font-family: $font-main;
        font-weight: 400;
        text-decoration: underline;
        font-size: 18px;
        line-height: 18px;

        &--arrow {
            position: relative;
            color: colour(black);
            font-family: $font-alt;
            font-size: 1.125rem;
            letter-spacing: 2px;
            margin-left: 22px;

            &::before {
                content: '';
                background: url('/assets/img/icons/icon-arrow-simple.svg') center center no-repeat;
                background-size: contain;
                position: absolute;
                width: 12px;
                height: 12px;
                left: -22px;
                top: 6px;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__stripped {
        font-family: inherit;
        color: unset;
        font-weight: inherit;

        &:hover {
            color: unset;
        }
    }
}

//------------------------------------
// Block Link
//------------------------------------
[data-block-link] {
    cursor: pointer;
}

//------------------------------------
// Redactor Content
//------------------------------------
.redactor-content {

    p {
        font-size: 1.125rem;
        line-height: 1.625rem;
    }

    ul {
        font-size: 1.125rem;
        margin-bottom: 1rem;
        margin-left: 2rem;
        list-style: disc;

        li {
            display: list-item;
            padding: 0.5rem 0;
            line-height: 1.625rem;
            list-style-type: disc;
        }
    }
}
