@use 'sass:math';

#search {
    h1 {
        font-size: clamp-sizes(2.5, 3.125);

        @include media(md, min-width) {
            text-align: left;
        }
    }

    section {
        &:first-of-type {
            margin-top: 3rem;
        }
    }

    .hero__content {
        flex-basis: 400px;
        padding: 0;
        margin: 0;

        @include media(md) {
            flex-basis: 100%;
        }
    }

    .search {
        flex: 1;

        input {
            border-radius: 5px;
            margin-top: 0;
            padding-left: 3rem;
        }

        &__hero {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include media(md) {
                flex-wrap: wrap;
            }
        }

        .form__item {
            position: relative;
            margin: 0;

            &::after {
                content: '';
                background: url('/assets/img/icons/search.svg');
                position: absolute;
                top: 13px;
                left: 13px;
                width: 22px;
                height: 22px;
            }

            input {
                margin-bottom: 0;
            }
        }

        .form__submit {
            flex: 0;
        }
    }

    .results {
        margin-bottom: 3rem;

        &__header {
            display: flex;
            align-items: center;
            border-top: 1px solid colour(grey);
            border-bottom: 1px solid colour(grey);
            padding: 0.75rem 0;

            h2 {
                display: inline-block;
                font-size: clamp-sizes(1.65, 1.875);
                margin: 0;
                text-transform: none;
            }
        }

        .tiles {
            justify-content: flex-start;

            .tile {
                flex-basis: calc((100% / 4) - 1.5em);
                min-width: unset;

                @include media(md) {
                    flex-basis: 100%;
                }
            }
        }
    }
}
