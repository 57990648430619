.orders {
    &__listing {
        h2 {
            font-family: $font-handwritten;
            font-size: clamp(20px, 5vw + 1rem, 30px);
            font-weight: 600;
            line-height: 35px;
            letter-spacing: 1px;
            text-align: left;
            color: black;
            margin-bottom: 30px;

            &.order-not-found {
                font-family: $font-alt;
                font-size: 20px;
                font-weight: 600;
                line-height: 25px;
                letter-spacing: 1px;
                color: black;
                text-transform: none;
                margin-bottom: 0;
            }
        }

        .pagination {
            &__next {
                margin-right: 0;
            }
        }
    }

    &__header {
        display: grid;
        grid-template-columns: 75px 0.75fr 2fr 100px;
        gap: 2rem;
        padding: 0 1rem 13px;
        @include media(md) {
            display: none;
        }

        > * {
            font-family: $font-alt;
            font-size: 20px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 1px;
            text-align: left;
            text-transform: uppercase;
            color: black;
        }
    }
}

.order {
    display: grid;
    grid-template-columns: min-content 0.75fr 2fr 100px;
    gap: 2rem;
    align-items: center;
    padding: 1rem;
    @include media(md) {
        grid-template-columns: auto;
        grid-template-rows: auto 2fr 1fr;
        padding: 0;
        margin-bottom: 2rem;
        gap: 0;
        border: 1px solid colour(light-grey);
    }

    &:nth-child(odd) {
        background: colour(light-grey);
        @include media(md) {
            background: unset;
        }
    }

    div {
        font-family: $font-main;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
    }

    > * {
        @include media(md) {
            padding: 0.75rem;
        }

        &:nth-child(2) {
            @include media(md) {
                display: none;
            }
        }

        &:nth-child(3) {
            max-width: 400px;

            @include media(md) {
                background: colour(white);
            }
        }

        &:nth-child(4) {
            font-family: $font-alt;
            font-size: clamp(20px, 5vw + 1rem, 30px);
            font-weight: 600;
            line-height: 35px;
            letter-spacing: 1px;
            text-align: right;
        }

        span {
            display: none;
            @include media(md) {
                display: block;
                font-family: $font-alt;
                font-size: 0.75rem;
                letter-spacing: 2px;
                margin-bottom: 0.35rem;
            }
        }
    }

    &__number {
        background-color: colour(teal);
        color: white;

        a {
            color: white;
        }

        &:hover {
            background-color: darken(colour(teal), 10%);
        }

        @include media(md) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        span {
            font-family: $font-header;
            font-size: 1.125rem;
            text-transform: uppercase;
            letter-spacing: unset;
        }

        .tag {
            padding: 10px;
            color: white;
            text-align: center;
            font-family: $font-noto;
            font-size: 18px;
            font-weight: 600;
            line-height: 30px;

            @include media(md) {
                padding: 0;
            }
        }
    }

    &__summary {
        background: colour(grey);
        padding: 4rem 3rem;
        @include media(md) {
            padding: 2rem;
        }

        h2 {
            text-align: center;
        }
    }

    &__address {
        font-size: 1.125rem;
        line-height: 1.625rem;

        .heading {
            font-size: 1.125rem;
            letter-spacing: 2px;
            margin: 0.75rem 0;
        }
    }

    &__item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
        margin: 4rem 0;
        @include media(md) {
            flex-wrap: wrap;
            gap: 1rem;
        }

        :nth-child(1) {
            @include media(md) {
                flex-basis: 100%;
            }
        }

        :nth-child(2) {
            flex: 1;
        }

        :nth-child(3) {
            font-family: $font-alt;
            font-size: 1.5rem;
        }

        h3 {
            font-size: clamp-sizes(1.65, 1.875);
        }

        img {
            height: 110px;
            width: 110px;
            object-fit: cover;
            @include media(md) {
                width: 100%;
            }
        }

        span {
            font-family: $font-header;
            font-size: 0.875rem;
        }
    }

    &__pricing {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        > * {
            display: grid;
            grid-template-columns: repeat(2, auto);
            align-items: center;
            justify-content: space-between;
            font-family: $font-alt;
            font-size: 1.5rem;
        }

        //:nth-child(4) {
        //    font-family: $font-main;
        //    font-size: 1rem;
        //    text-decoration: underline;
        //
        //    a {
        //        color: colour(black);
        //        font-weight: 400;
        //
        //        &:hover {
        //            color: colour(teal);
        //        }
        //    }
        //}

        .total {
            font-family: $font-header;
            font-size: 1.875rem;
        }

        .type {
            font-family: $font-main;
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 1.563rem;
            text-transform: uppercase;
        }

        [data-shipping-container] {
            text-align: right;
        }
    }

    &__adjustment {
        font-size: 18px;
        padding-left: 20px;
    }

    &__shipping-recalculate {
        font-size: 14px;
        text-align: right;
        color: colour(teal);
        grid-row: 2;
        grid-column: 2;
        margin-top: 0.3rem;
    }
}

.order__addresses {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

body.site-humphrys {
    .order {
        &__pricing {
            .total {
                font-family: $humphrys-font-content;
                font-weight: 700;
            }
        }
    }
}
