.resource-card {
    &__eyebrow {
        font-size: 0.875rem;
    }

    &__heading {
        line-height: 1.1;
        transition: color 200ms ease;
    }

    a {
        display: block;
    }

    a:focus {
        outline: 2px dashed colour(teal);
        outline-offset: 5px;
    }
}

.resource-card:hover {
    .resource-card__heading {
        color: colour(teal);
    }
}
