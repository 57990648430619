.product-card {
    h3,
    h3 a {
        transition: color 200ms ease;
    }
}

.product-card .tile__content h3 a:hover {
    color: colour(teal);
}

.product-card .tile__image:hover {
    & + .tile__content h3,
    & + .tile__content h3 a {
        color: colour(teal);
    }
}
