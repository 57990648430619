.reviews {
    .tiles__headline {
        text-align: center;
        letter-spacing: 1px;

        @include media(sm) {
            justify-content: flex-start;
            text-align: left;
        }
    }

    &__meta {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1.25rem;

        @include media(lg) {
            grid-template-columns: 1fr;
            justify-items: center;
        }

        @include media(sm) {
            justify-items: start;
        }
    }

    &__sort {
        grid-column: 3;
        justify-self: end;

        @include media(lg) {
            grid-column: 1;
            justify-self: auto;
        }

        &-label {
            margin-bottom: 0.5rem;
            font-size: 0.875rem;
            font-family: $font-main;
            text-transform: none;
            letter-spacing: 0;
        }

        .custom-select {
            width: 17.1875rem;
        }
    }

    &__overview {
        grid-column: 2;
        text-align: center;

        @include media(lg) {
            grid-column: 1;
        }

        @include media(sm) {
            display: grid;
            grid-template-columns: max-content max-content;
            grid-template-rows: 1fr 1fr;
            column-gap: 1.25rem;
            align-items: center;
        }
    }

    &__stars {
        img {
            width: 2.1875rem;

            @include media(sm) {
                width: 1.25rem;
            }
        }
    }

    &__overview-heading {
        margin: 1rem 0 0;

        @include media(sm) {
            margin: 0;
            font-size: 1.125rem;
        }
    }

    &__total {
        margin-top: 0.5rem;

        @include media(sm) {
            text-align: left;
        }
    }

    &__reviews {
        margin-top: 2.5rem;

        @include media(sm) {
            padding-top: 1.4rem;
            border-top: 1px solid colour(grey);
        }
    }

    &__more {
        margin-top: 2.5rem;
        text-align: center;
    }

    .tiles .tile {
        @include media(sm) {
            text-align: left;
            margin-left: 0;
        }

        blockquote {
            @include media(sm) {
                margin-left: 0;
            }
        }

        .stars img {
            width: 1.25rem;
        }
    }
}


//------------------------------------
// Inline Reviews
//------------------------------------
.reviews-link {
    font-size: 0.875rem;
}
