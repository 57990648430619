.tiles {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(322px, 1fr));
    gap: 1.5em;
    margin: 0;

    @include media(sm) {
        gap: 2rem;
    }

    @include media(md) {
        grid-template-columns: 1fr;
    }

    &.--four {
        grid-template-columns: repeat(4, 1fr);

        @include media(lg) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media(md) {
            grid-template-columns: 1fr;
        }
    }

    @include media(sm) {
        margin: 0 !important;
    }

    a:not(.button) {
        color: colour(black);
        font-weight: unset;
    }

    a.button {
        margin-top: 1rem;
    }

    > .tile {
        flex-basis: calc((100% / 3) - 1.5em);
        margin: 0.75em;
        min-width: 322px;

        @include media(lg) {
            flex-basis: calc((100% / 2) - 1.5em);
        }

        @include media(md) {
            flex-basis: 100%;
            margin-bottom: 2rem;
            min-width: auto;
        }
    }

    &.--four {
        > .tile {
            flex-basis: calc((100% / 4) - 1.5em);
            margin: 0.75em;
            min-width: unset; // This has to be unset a lot, rethink?

            @include media(lg) {
                flex-basis: calc((100% / 2) - 1.5em);
            }

            @include media(md) {
                flex-basis: 100%;
                margin-bottom: 2rem;
            }

            &__image {
                max-height: 310px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }

    &__aligned {
        .tile {
            display: grid;
            grid-template-rows: max-content auto;
            @include media(md) {
                display: flex;
                flex-direction: column;
            }

            &__content {
                display: flex;
                flex-direction: column;

                .button {
                    margin-top: auto;
                    max-width: fit-content;
                }
            }
        }
    }

    &__headline {
        display: flex;
        justify-content: center;
        margin: 0 0 1rem;
        flex-direction: column;

        @include media(md) {
            flex-wrap: wrap;
        }

        h2 {
            margin: 0;
        }

        p {
            font-size: 1.125rem;
            line-height: 1.625rem;

            @include media(md) {
                margin-top: 0.75rem;
            }

            @include media(sm) {
                font-size: 1rem;
                line-height: 1.25rem;
            }
        }

        &--center {
            justify-content: center;
            text-align: center;
        }
    }

    .tile {
        &__image {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        &__content {
            h3 {
                color: colour(black);
                font-family: $font-alt;
                font-size: 1.5rem;
                text-transform: uppercase;
                margin: 5px 0 0;
            }

            p {
                color: colour(black);
                font-weight: 400;
            }
        }
    }
}

.site-humphrys {
    .tiles {
        > .tile {
            flex-basis: calc((100% / 3) - 1.5em);
            margin: 0;
            min-width: 322px; // This can be problematic

            @include media(lg) {
                flex-basis: calc((100% / 2) - 1.5em);
            }

            @include media(md) {
                flex-basis: 100%;
                margin-bottom: 0;
                min-width: auto;
            }
        }

        .tile {
            &__content {
                .subtext {
                    display: none;
                }

                h3 {
                    font-family: $humphrys-font-content;
                    font-size: 1.5rem;
                    font-weight: 500;
                    line-height: 28px;
                    text-align: left;
                    text-transform: none;
                    margin: unset;
                    margin-top: 12px;

                    @include media(md) {
                        font-size: 1rem;
                    }
                }

                a {
                    color: $humphrys-dark-blue;

                    &:hover {
                        color: $humphrys-yellow;
                    }
                }

                p {
                    font-family: $humphrys-font-content;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    margin-top: 12px;
                }
            }

            .product {
                &__full {
                    justify-content: space-between;
                    align-items: center;

                    p {
                        font-family: $humphrys-font-content;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 24px;
                        margin-bottom: 0;
                    }
                }

                &__simple {
                    margin-bottom: 20px;

                    .text-right {
                        text-align: right;
                    }
                }
            }
        }

        &__headline {
            h2 {
                font-family: $humphrys-font-heading;
                font-size: 48px;
                font-weight: 600;
                line-height: 48px;
                color: colour(black);
                text-transform: none;
                margin-bottom: 1rem;
                text-align: center;

                @include media(md) {
                    font-size: 36px;
                    line-height: 40px;
                }
            }

            p {
                font-family: $humphrys-font-content;
                font-size: 20px;
                font-weight: 400;
                line-height: 28px;
            }
        }
    }
}
