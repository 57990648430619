/* == Mixins == */
///
/// media query mixin
///
@mixin media($bp, $rule: max-width) {
    @media screen and (#{$rule}: map-get($breakpoints, $bp)) {
        @content;
    }
}

///
/// container mixin
///
@mixin container($max-width: 1320px, $padding: $gutter * 0.5) {
    margin-left: auto;
    margin-right: auto;
    max-width: $max-width;
    padding-left: $padding;
    padding-right: $padding;
    position: relative;

    @include media(lg) {
        padding-left: $padding * 2;
        padding-right: $padding * 2;
    }

    @include media(sm) {
        padding-left: $padding;
        padding-right: $padding;
    }
}

///
/// absolute center content
/// (parent container must be 'postition:relative')
///
@mixin center-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// We aren't using this mixin, but if we ever need to, it's here.
@mixin colour-line {
    position: relative;
}

@mixin line-height-crop($line-height) {
    &::before {
        content: '';
        display: block;
        height: 0;
        width: 0;
        margin-top: calc((1 - #{$line-height}) * 0.5em);
    }
}
