#commerce {
    main {
        aside {
            background: colour(white);
            border-left: 1px solid colour(grey);
            padding: 4rem 3rem;

            @include media(lg) {
                margin: 0 -30px;
            }

            @include media(sm) {
                margin: 0 -15px;
            }

            .link__simple {
                display: block;
            }

            .aside-button {
                margin-top: 2rem;
                text-align: right;
            }
        }
    }
    section {
        &:first-of-type {
            margin-top: 4rem;
        }
        &.mobile-nav-overlay {
            margin: 0;
        }
    }

    .hero {
        &__thank-you {
            text-align: left;

            h1 {
                @include line-height-crop(1.4);
                color: colour(black);
                margin: 0;
            }

            .heading {
                color: colour(black);
                font-size: 1.125rem;
            }

            .hero__content {
                display: flex;
                flex-direction: row;
                gap: 2rem;
                align-items: center;
                max-width: unset;
                padding-bottom: 0;
            }
        }

        &__content.sr-only {
            padding: 0;
        }
    }

    .header {
        font-size: clamp-sizes(1.65, 1.875);
        letter-spacing: 2px;
        margin-bottom: 1.75em;

        &__form {
            margin-bottom: 0.75em;
        }

        p {
            font-size: 0.875rem;
            font-weight: 400;
            letter-spacing: 0;
            margin-top: 0.5rem;
            text-transform: none;
        }
    }

    .customer {
        &__info {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 0 2rem;

            h3 {
                font-family: $font-alt;
                font-size: 1.125rem;
                letter-spacing: 2px;
            }

            img {
                margin-right: 0.5rem;
            }
        }
    }
    .user {
        &__info {
            margin-bottom: 4rem;

            h3 {
                font-family: $font-alt;
                font-size: 1.125rem;
                letter-spacing: 2px;
            }
        }
    }
    .credit {
        &__saved {
            background: white;
            padding: 2rem;
            margin-bottom: 3rem;

            .form__item {
                margin-left: 0;
            }
        }

        &__type {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;

            h3 {
                font-size: 1.875rem;
                font-weight: 400;
                line-height: 1rem;
                letter-spacing: 1px;
                text-align: left;
            }
        }

        &__new {
            margin-bottom: 2rem;

            .form {
                &__row {
                    label,
                    em {
                        font-family: $font-main;
                        font-size: 0.875rem;
                        font-weight: 400;
                        line-height: 1.25rem;
                        font-style: normal;
                    }
                }
            }
        }
    }

    .form {
        &__submit {
            .button {
                font-family: $font-noto;
                font-size: 1.25rem;
                font-weight: 700;
                line-height: 1.2;
                letter-spacing: 0.02em;
                padding: 7px 20px;
            }
        }
    }
}

//------------------------------------
// Order Summary
//------------------------------------
.order-summary {
    .order__pricing + * {
        margin-top: 3rem;
    }

    h2 {
        font-family: $font-alt;
        font-size: 1.875rem;
        font-weight: 600;
        line-height: 2.188rem;
        color: colour(black);
    }

    .line {
        &-item {
            margin-top: 5px;
        }
    }

    .discounts {
        .discounts {
            &__input {
                .button {
                    font-family: $font-noto;
                    font-size: 1.25rem;
                    font-weight: 700;
                    line-height: 1.2;
                    letter-spacing: 0.02em;
                    padding: 7px 20px;
                }
            }
        }
    }
}

//------------------------------------
// Empty Cart Notice
//------------------------------------
.empty-cart {
    margin-top: 1.5rem;
    padding: 2rem;
    background-color: rgba(colour(teal), 0.5);
    border: 1px solid colour(teal);
    font-size: 18px;

    a {
        color: $plain-text;
        text-decoration: underline;
    }
}

//------------------------------------
// Site Humphrys
//------------------------------------

#commerce {
    &.site-humphrys {
        .checkout-header {
            padding: 0;

            @include media(nav) {
                padding: 0.75rem 0;

                .logo {
                    order: 2;
                }
                
            }
        }

        .container {
            &__commerce {
                section {
                    margin: 50px 0;

                    &:first-of-type {
                        margin-top: 50px;
                    }

                    p, a {
                        font-family: $humphrys-font-content;
                        font-size: 1rem;
                        line-height: 1.25rem;
                    }
                }

                .credit__new  {
                    .form__row {
                        em {
                            font-family: $humphrys-font-content;
                            font-size: 1rem;
                            line-height: 1.25rem;
                            letter-spacing: normal;
                            font-style: italic;
                        }
                    }
                }

                .discounts {
                    &__input {

                        input {
                            margin-top: 0;
                            background: white;
                            color: black;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 1.15;
                            padding: 20px;
                            border: 1px solid #000;
                            margin: 0;
                            margin-right: 20px;

                            &:focus {
                                outline: 2px dashed $humphrys-blue;
                                outline-offset: 3px;
                            }
                        }

                        .button {
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 28px;
                            padding: 10px 0;
                            font-family: $humphrys-font-content;
                        }
                    }
                }

                .link__simple {
                    color: $humphrys-yellow;

                    &:hover {
                        color: $humphrys-blue;
                    }
                }

                .heading {
                    font-family: $humphrys-font-heading;
                    font-weight: 600;
                    color: #000;
                    margin-bottom: 5px;
                }

                h2 {
                    font-family: $humphrys-font-heading;
                    font-size: 36px;
                    line-height: 40px;
                    font-weight: 600;
                    text-transform: unset;
                    text-align: left;
                    color: black;
                    letter-spacing: normal;

                    &.header {
                        margin-bottom: 44px;
                    }
                }

                .hero {
                    &__content {
                        margin-top: 20px;
                        .h4 {
                            color: colour(slate-gray);
                            margin-bottom: 10px;
                        }

                        h1 {
                            text-align: left;
                        }
                    }
                }

                .user__info, .customer__info {
                    h3 {
                        font-family: $humphrys-font-heading;
                        font-weight: 600;
                        color: #000;
                        margin-bottom: 5px;
                    }

                    p, a {
                        font-family: $humphrys-font-content;
                        font-size: 1rem;
                        line-height: 1.25rem;
                    }
                }

                form {
                    h2 {
                        font-family: $humphrys-font-heading;
                        font-size: 36px;
                        line-height: 40px;
                        font-weight: 600;
                        text-transform: unset;
                        text-align: left;
                        color: black;
                        letter-spacing: normal;
                    }

                    .form {
                        &__header--flex {
                            p, a {
                                font-family: $humphrys-font-poppins;
                                font-size: 1rem;
                                line-height: 1.25rem;
                                padding: 0;
                            }
                        }

                        &__item {
                            label {
                                font-family: $humphrys-font-button;
                                font-size: 18px;
                                font-weight: 700;
                                line-height: 1.1;
                                color: colour(black);

                                @media (max-width: 991px) {
                                    font-size: 14px;
                                }
                                
                                .fui-required {
                                    color: colour(black);
                                }

                                input, select {
                                    font-family: $humphrys-font-button;
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 1.15;
                                    padding: 20px;
                                    margin-bottom: 1rem;
                                    margin-top: 5px;

                                    &:focus {
                                        outline: 2px dashed $humphrys-blue;
                                        outline-offset: 3px;
                                    }
                                }
                            }
                        }

                        &__checkbox {
                            label {
                                font-family: $humphrys-font-button;
                                font-size: 18px;
                                font-weight: 700;
                                line-height: 1.1;
                                text-transform: capitalize;
                                color: colour(black);
                            }
                        }
                    }
                }

                .form {
                    &__submit {
                        .button {
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 28px;
                            letter-spacing: normal;
                            padding: 12px 0;
                            font-family: $humphrys-font-button;
                        }
                    }
                }

                .order-summary {
                    @include media(md) {
                        padding: 40px 30px;
                    }

                    h2 {
                        font-family: $humphrys-font-heading;
                        font-size: 36px;
                        line-height: 40px;
                        font-weight: 600;
                        text-transform: unset;
                        text-align: left;
                        color: black;
                        margin-bottom: 44px;
                        letter-spacing: normal;
                    }

                    .line-item {
                        p {
                            font-family: $humphrys-font-content;
                            font-size: 1rem;
                            line-height: 1.25rem;
                            padding: 0;

                            &.line-item-options {
                                font-size: 0.875rem;
                            }
                        }
                    }

                    .order__pricing {
                        & > * {
                            font-family: $humphrys-font-content;


                            .order__shipping-recalculate {
                                font-size: 1.125rem;
                                line-height: 20px;
                                color: $humphrys-yellow;

                                &:hover {
                                    color: $humphrys-blue;
                                }
                            }

                            span {
                                font-size: 1.125rem;
                                line-height: 20px;
                                color: #000;
                                font-weight: 700;

                                &.tax, &.shipping, &[data-shipping-container] {
                                    font-weight: 400;
                                }

                                &.total {
                                    font-size: 1.5rem;
                                    line-height: 1.4em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}