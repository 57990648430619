#home {
    .products {
        .tiles {
            &.--four {
                margin: 0 -36px;

                .tile {
                    flex-basis: calc(25% - 72px);
                    margin: 0 36px;

                    &__content {
                        h3 {
                            color: colour(teal);
                            font-size: clamp(20px, 5vw + 1rem, 30px);
                            font-weight: 600;
                            line-height: 35px;
                            letter-spacing: 1px;
                            text-transform: uppercase;
                            margin-top: 15px;
                            margin-bottom: 9px;

                            a {
                                color: colour(teal);
                            }
                        }

                        p {
                            font-family: $font-main;
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 24px;
                        }
                    }
                }
            }

            &__headline {
                h2 {
                    @extend .h1;
                    text-align: center;
                    margin-bottom: 36px;
                    z-index: 1;
                    font-size: clamp(40px, 5vw + 1rem, 110px);
                    font-weight: 400;
                    line-height: 95px;
                    letter-spacing: 1px;
                }
            }
        }
    }

    .imageWithText {
        align-items: start;

        h2 {
            font-size: clamp(40px, 5vw + 1rem, 71px);
            font-weight: 400;
            line-height: 67.45px;
            letter-spacing: 1px;
            margin-bottom: 34px;
        }

        .cta__text {
            margin-bottom: 34px;

            p {
                font-family: $font-main;
                font-size: 24px;
                font-weight: 400;
                line-height: 30px;
            }
        }

        .button {
            padding-top: 7px;
            padding-bottom: 7px;
            letter-spacing: 0.02em;
        }
    }

    .quoteHome {
        .tiles {
            .tile {
                blockquote {
                    margin: 0;
                    font-size: clamp(12px, 5vw + 1rem, 18px);
                    font-weight: 400;
                    line-height: 24px;
                    text-align: center;
                    margin-bottom: 23px;
                }

                &__heading {
                    font-size: clamp(20px, 5vw + 1rem, 30px);
                    font-weight: 600;
                    line-height: 35px;
                    letter-spacing: 1px;
                    text-align: center;
                    margin-bottom: 5px;
                }

                &__subheading {
                    font-family: $font-main;
                    font-size: clamp(8px, 5vw + 1rem, 14px);
                    font-weight: 400;
                    line-height: 20px;
                    text-align: center;
                    margin: 0 auto;
                    max-width: 330px;
                }
            }
        }

        h2 {
            font-size: clamp(40px, 5vw + 1rem, 71px);
            font-weight: 400;
            line-height: 67.45px;
            letter-spacing: 1px;
            margin-bottom: 106px;
            max-width: 885px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }

    .slides--simple {
        h2 {
            font-size: clamp(40px, 5vw + 1rem, 71px);
            font-weight: 400;
            line-height: 67.45px;
            letter-spacing: 1px;
            margin-bottom: 85px;
        }
    }

    .imageWithText,
    .quoteHome,
    .slides--simple,
    .products {
        h2 {
            mix-blend-mode: multiply;
        }
    }
}

#home.site-humphrys {
    .products {
        &__tiles {
            margin-top: 80px;
            margin-bottom: 80px;
        }

        .product {
            &__full {
                margin-top: 12px;
            }
        }

        .tiles {
            &.--four {
                margin: unset;
                grid-template-columns: repeat(4, 1fr);
                gap: 3rem;

                @include media(nav) {
                    grid-template-columns: repeat(3, 1fr);
                    gap: 2rem;
                }

                @include media(md) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include media(sm) {
                    grid-template-columns: auto;
                }

                .tile {
                    margin: unset;
                    min-width: unset;

                    &.product-card {
                        &:hover {
                            a {
                                color: $humphrys-yellow;
                            }
                        }
                    }

                    &__content {
                        h3 {
                            font-family: $humphrys-font-content;
                            font-size: 1.5rem;
                            font-weight: 500;
                            line-height: 28px;
                            text-align: left;
                            text-transform: none;
                            margin: unset;
                            margin-top: 12px;

                            @include media(md) {
                                font-size: 1rem;
                            }
                            a {
                                color: $humphrys-dark-blue;
                            }
                        }
                        a {
                            color: $humphrys-dark-blue;

                            &:hover {
                                color: $humphrys-yellow;
                            }
                        }

                        p {
                            font-family: $humphrys-font-content;
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 24px;
                        }

                        span {
                            display: none;
                        }
                    }
                }
            }

            &__headline {
                margin-bottom: 200px;

                @include media(nav) {
                    margin-bottom: 0;
                }

                h2 {
                    font-family: $humphrys-font-heading;
                    font-size: 48px;
                    font-weight: 600;
                    line-height: 48px;
                    color: $plain-text;
                    text-transform: none;
                    margin-bottom: 40px;

                    @include media(md) {
                        font-size: 36px;
                        line-height: 40px;
                    }
                }
            }
        }
    }

    .imageWithText {
        &_stretched {
            position: relative;
            min-height: 700px;

            @include media(nav) {
                min-height: 500px;
            }

            .imageWithText {
                min-height: 700px;
                align-items: center;

                @include media(nav) {
                    min-height: 500px;
                }

                @include media(md) {
                    padding: 0;
                }

                :nth-child(1n) {
                    flex: 1;
                    @include media(md) {
                        flex: 0 0 100%;
                    }
                }
                :nth-child(2n) {
                    flex: 2;

                    @include media(lg) {
                        flex: 1;
                    }

                    @include media(md) {
                        flex: 0 0 100%;
                    }
                }

                &__image {
                    position: unset;
                }

                &.image--right {
                    .responsive-image-wrapper {
                        left: 0;
                        right: 38%;

                        @include media(lg) {
                            right: 50%;
                        }
                    }
                }
            }

            .cta__home {
                min-height: 700px;
                position: unset;

                @include media(nav) {
                    min-height: 500px;
                }

                .responsive-image-wrapper {
                    max-width: 1100px !important;
                    min-height: 700px !important;
                    position: absolute;
                    right: 0;
                    overflow: hidden;
                    object-fit: contain;
                    left: 38%;
                    top: 0;
                    bottom: 0;
                    height: 100%;
                    margin: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @include media(nav) {
                        min-height: 500px !important;
                    }

                    @include media(lg) {
                        left: 50%;
                    }

                    @include media(md) {
                        position: unset;
                        overflow: unset;
                        min-height: unset !important;
                        width: 100% !important;
                        max-width: 100% !important;
                        margin: unset;
                        max-height: unset !important;
                    }

                    @media screen and (min-width: 1700px) {
                        padding-right: 8%;
                    }
                }
            }
        }

        h2 {
            font-family: $humphrys-font-heading;
            font-size: 48px;
            font-weight: 600;
            line-height: 48px;
            color: $plain-text;
            text-transform: none;
            margin-bottom: 16px;

            @include media(md) {
                font-size: 36px;
                line-height: 40px;
            }
        }

        .cta__text {
            margin-bottom: 32px;

            p {
                font-family: $humphrys-font-content;
                font-size: 20px;
                line-height: 28px;
            }
        }
    }

    .quoteHome {
        .tiles {
            gap: 2em;

            .tile {
                margin: 0;

                blockquote {
                    margin: 0;
                    font-size: 20px;
                    line-height: 28px;
                    margin-bottom: 23px;
                }
                &__heading {
                    font-family: $humphrys-font-heading;
                    color: #000;
                }

                &__subheading {
                    font-family: $humphrys-font-content;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: center;
                    margin: 0 auto;
                    text-transform: uppercase;
                }
            }
        }

        h2 {
            font-family: $humphrys-font-heading;
            font-size: 48px;
            font-weight: 600;
            line-height: 48px;
            color: $plain-text;
            text-transform: none;
            margin-top: 0;
            margin-bottom: 32px;

            @include media(md) {
                font-size: 36px;
                line-height: 40px;
            }
        }
    }
}
