// Global hover effect for SVG logos used within links

a:has(svg.humphrys-logo) {
    .humphrys-logo-text {
        transition: 0.3s ease fill;
    }

    &:hover {
        .humphrys-logo-text {
            fill: $humphrys-yellow;
        }
    }
}
