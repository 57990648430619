/* Variables */
$colours: (
    black: #000,
    charcoal: #333333,
    super-dark-grey: #2f2f2f,
    dark-grey: #525252,
    grey: #eaeaea,
    light-grey: #f3f3f3,
    white: #fff,
    red: #F36B40, // For v2 design
    green: #257833,
    teal: #0F32A6, // For v2 design
    theme-blue: #0F32A6, // For v2 design
    cream: #fbe9c5,
    light_cream: #F1EEE9,
    blue: #39628d,
    metflag-blue :#2047D2,
    dark-gray : #666666,
    stone-gray: #9D9D9D,

    // Theme humphrys colors
    humphrys-theme-blue: #060084,
    humphrys-blue: #060084,
    humphrys-blue-lighter: #232066,
    humphrys-footer-blue: #030039,
    humphrys-dark-blue: #040057,
    humphrys-yellow: #FFAA00,
    humphrys-ivory: #F1ECDC,
    humphrys-gray: #A5A5A5,
    humphrys-black: #0E0F0D,
    slate-gray: #6B6B6B,
);

@function colour($colour) {
    @return map-get($colours, $colour);
}

/* Applied colors */
$plain-text: colour(black);
$meta-text: colour(dark-grey);
$link: colour(teal);
$link-hover: darken(colour(teal), 10%);
$border: lighten(colour(teal), 80%);

$humphrys-card-border: colour(humphrys-ivory);
$humphrys-link: colour(humphrys-theme-blue);
$humphrys-link-hover: lighten(colour(humphrys-blue), 20%);
$humphrys-yellow: colour(humphrys-yellow);
$humphrys-blue: colour(humphrys-blue);
$humphrys-ivory: colour(humphrys-ivory);
$humphrys-dark-blue: colour(humphrys-dark-blue);

$humphrys-button-primary-hover: darken(colour(humphrys-yellow), 5%);
$humphrys-button-secondary-hover: lighten(colour(humphrys-blue), 20%);

/* Fonts */
@font-face {
    font-family: 'Douglas-Calgury';
    font-weight: 700;
    src: url('/assets/fonts/Douglas-Calgury.woff2') format('woff2'), url('/assets/fonts/Douglas-Calgury.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Douglas-Aaronade';
    font-weight: 400;
    src: url('/assets/fonts/Douglas-Aaronade.woff2') format('woff2'), url('/assets/fonts/Douglas-Aaronade.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/barlow-thin-webfont.woff2') format('woff2'), url('/assets/fonts/barlow-thin-webfont.woff') format('woff');
    font-display: swap;
    font-weight: 100;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/barlow-light-webfont.woff2') format('woff2'), url('/assets/fonts/barlow-light-webfont.woff') format('woff');
    font-display: swap;
    font-weight: 300;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/barlow-regular-webfont.woff2') format('woff2'), url('/assets/fonts/barlow-regular-webfont.woff') format('woff');
    font-display: swap;
    font-weight: 400;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/Barlow-Medium.woff2') format('woff2'), url('/assets/fonts/Barlow-Medium.woff') format('woff');
    font-display: swap;
    font-weight: 500;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/Barlow-MediumItalic.woff2') format('woff2'), url('/assets/fonts/Barlow-MediumItalic.woff') format('woff');
    font-style: italic;
    font-display: swap;
    font-weight: 500;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/barlow-semibold-webfont.woff2') format('woff2'), url('/assets/fonts/barlow-semibold-webfont.woff') format('woff');
    font-display: swap;
    font-weight: 600;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/barlow-bold-webfont.woff2') format('woff2'), url('/assets/fonts/barlow-bold-webfont.woff') format('woff');
    font-display: swap;
    font-weight: 700;
}

@font-face {
    font-family: 'Barlow-Condensed';
    src: url('/assets/fonts/barlowsemicondensed-regular-webfont.woff2') format('woff2'), url('/assets/fonts/barlowsemicondensed-regular-webfont.woff') format('woff');
    font-display: swap;
    font-weight: 400;
}

@font-face {
    font-family: 'Barlow-Condensed';
    src: url('/assets/fonts/barlowsemicondensed-medium-webfont.woff2') format('woff2'), url('/assets/fonts/barlowsemicondensed-medium-webfont.woff') format('woff');
    font-display: swap;
    font-weight: 500;
}

@font-face {
    font-family: 'Barlow-Condensed';
    src: url('/assets/fonts/barlowsemicondensed-light-webfont.woff2') format('woff2'), url('/assets/fonts/barlowsemicondensed-light-webfont.woff') format('woff');
    font-display: swap;
    font-weight: 300;
}

@font-face {
    font-family: 'Barlow-Condensed';
    src: url('/assets/fonts/barlowsemicondensed-extralight-webfont.woff2') format('woff2'), url('/assets/fonts/barlowsemicondensed-extralight-webfont.woff') format('woff');
    font-display: swap;
    font-weight: 200;
}

@font-face {
    font-family: 'Barlow-Condensed';
    src: url('/assets/fonts/BarlowCondensed-SemiBold.woff2') format('woff2'), url('/assets/fonts/BarlowCondensed-SemiBold.woff') format('woff');
    font-display: swap;
    font-weight: 600;
}


@font-face {
    font-family: 'Labor-Union';
    font-weight: 400;
    src: url('/assets/fonts/LaborUnion-Regular-webfont.woff2') format('woff2'), url('/assets/fonts/LaborUnion-Regular-webfont.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'NotoSansMono-Condensed';
    font-style: normal;
    font-weight: 100 900;
    font-stretch: 75%;
    src: url('/assets/fonts/NotoSansMono_Condensed.woff2') format('woff2');
    font-display: swap;
}

@font-face {
    font-family: 'Prumo Banner';
    font-weight: 700;
    src: url('/assets/fonts/prumo-banner-bold.woff2') format('woff2'), url('/assets/fonts/prumo-banner-bold.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Prumo Banner';
    font-weight: 600;
    src: url('/assets/fonts/prumo-banner-semi-bold.woff2') format('woff2'), url('/assets/fonts/prumo-banner-semi-bold.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Benton Sans Pro';
    font-weight: 400;
    src: url('/assets/fonts/benton-sans-pro-regular.woff2') format('woff2'), url('/assets/fonts/benton-sans-pro-regular.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Benton Sans Pro';
    font-weight: 500;
    src: url('/assets/fonts/benton-sans-pro-medium.woff2') format('woff2'), url('/assets/fonts/benton-sans-pro-medium.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Benton Sans Pro';
    font-weight: 400;
    src: url('/assets/fonts/benton-sans-pro-cond.woff2') format('woff2'), url('/assets/fonts/benton-sans-pro-cond.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Benton Sans Pro Cond';
    font-weight: 500;
    src: url('/assets/fonts/benton-sans-pro-cond-medium.woff2') format('woff2'), url('/assets/fonts/benton-sans-pro-cond-medium.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Kanit';
    font-weight: 700;
    src: url('/assets/fonts/kanit-semibold-webfont.woff2') format('woff2'), url('/assets/fonts/kanit-semibold-webfont.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-weight: 400;
    src: url('/assets/fonts/poppins-webfont.woff2') format('woff2'), url('/assets/fonts/poppins-webfont.woff') format('woff');
    font-display: swap;
}

// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@100..900&display=swap');

$font-main: 'Barlow', sans-serif;
$font-alt: 'Barlow-Condensed', sans-serif;
$font-header: 'Labor-Union', sans-serif;
$font-handwritten: 'Barlow-Condensed', serif;
$font-noto: "NotoSansMono-Condensed", monospace;

$humphrys-font-main: 'Prumo Banner', sans-serif;
$humphrys-font-title: 'Prumo Banner', sans-serif;
$humphrys-font-heading: 'Kanit', sans-serif;
$humphrys-font-content: 'Benton Sans Pro';
$humphrys-font-button: 'Benton Sans Pro';
$humphrys-font-content-cond: 'Benton Sans Pro Cond';
$humphrys-font-poppins: 'Poppins', sans-serif;

/* Media Query Breakpoints */
$breakpoints: (
    nav: 1100px,
    xs: 320px,
    sm: 576px,
    md: 800px,
    lg: 1024px,
    xl: 1200px,
    container: 1320px,
);

/* gutter default */
$gutter: 30px;

/* Misc */
$texture: url('/assets/img/accents/texture.jpg');
// $texture-warm-white: url('/assets/img/texture-warm-white.jpg');
$texture-cream: url('/assets/img/accents/bg-texture-cream.jpg'); // For v2 design
$texture-teal: url('/assets/img/accents/texture-teal.jpg');

$texture-humphrys-ivory: url('/assets/img/accents/humphrys-texture-ivory.png');
$texture-humphrys-yellow: url('/assets/img/accents/humphrys-texture-yellow.png');
$texture-humphrys-blue: url('/assets/img/accents/humphrys-texture-blue.png');