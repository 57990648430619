.site-header {
    position: relative;
    background: black;
    padding: 0;

    @include media(nav) {
        padding: 0.75rem 0;
    }

    > .container {
        @include media(lg) {
            flex-wrap: wrap;
        }
    }

    a {
        color: colour(cream);
        text-transform: uppercase;
        font-family: $font-alt;
        font-size: 1.125rem;
        line-height: 1.375;
        letter-spacing: 1px;

        &:hover {
            color: colour(white);
        }
    }

    .logo {
        // max-width: 180px; // Width of original logo
        // max-width: 220px; // Width of 150-years logo
        max-width: 207px; // Width of v2 logo
        width: 100%; // Width of v2 logo
        margin-bottom: 0;
        svg.humphrys-logo {
            width: 100%;
            height: auto;
        }

        @include media(nav) {
            order: 2;
            max-width: 85px;
            margin-bottom: 0;
        }

        &--desktop {
            display: block;

            @include media(nav) {
                display: none;
            }
        }

        &--mobile {
            display: none;

            @include media(nav) {
                display: block;
            }
        }
    }

    &.checkout-header {
        .logo {
            @include media(nav) {
                max-width: 100px;
                order: initial;
            }
        }
    }

    .hamburger {
        display: none;
        border: none;
        background: transparent;
        width: 18px;
        height: 30px;
        margin: 0;
        padding: 0;
        cursor: pointer;
        z-index: 100;

        &::after,
        &::before,
        > div {
            background-color: white;
            content: '';
            display: block;
            height: 2px;
            width: 18px;
            margin: 5px 0;
            transition: all 0.2s ease-in-out;
        }
    }

    .menu-wrapper {
        margin-left: 37px;
        align-self: stretch;
        @include media(nav) {
            order: 3;
            margin: 0;
            display: inline-flex;
            width: unset;
        }
    }

    nav#primary {
        align-self: stretch;
        justify-content: center;
        align-items: center;
        display: flex;

        @include media(nav) {
            margin-top: 0;
        }

        @include media(lg) {
            width: 100%;
            order: 3;
        }

        .primary-nav-list {
            display: flex;
            height: 100%;
            align-items: center;

            @include media(lg) {
                justify-content: center;
                margin-top: 0.75rem;
            }

            .primary-nav-item + .primary-nav-item {
                margin-left: 2rem;
            }

            .primary-nav-item {
                display: flex;
                height: 100%;
                align-items: center;

                &:hover,
                &:focus-within {
                    .subnav {
                        display: block;
                    }
                }

                &.current {
                    .primary-nav-item {
                        &::after {
                            opacity: 1;
                            transform: scaleX(1);
                        }
                    }
                }

                &.is-active {
                    .primary-nav-link {
                        color: colour(white);
                    }
                }
            }

            .primary-nav-link {
                display: flex;
                height: 100%;
                align-items: center;
                padding: 0 10px;
                color: colour(light_cream);
                font-size: 20px;
                line-height: 25px;

                &:hover {
                    color: colour(white);
                }
            }
        }
    }

    .user-info {
        position: relative;
        margin-top: 20px;
        display: flex;

        a,
        button {
            transition: 0.3s ease opacity;
            &:hover {
                opacity: 0.7;
            }
        }

        &__cart {
            position: relative;

            .indicator {
                position: absolute;
                display: grid;
                place-items: center;
                top: -0.625rem;
                right: -0.625rem;
                width: 1.3rem;
                height: 1.3rem;
                text-align: center;
                border-radius: 100%;
                color: colour(black);
                background-color: colour(red);
                font-size: 14px;
                font-family: $font-alt;
            }
        }

        @include media(nav) {
            margin-top: 0;
            order: 3;
        }

        @include media(nav) {
            &__account,
            &__search {
                display: none;
            }
        }

        > * + *:not(.header-search) {
            margin-left: 2rem;

            @include media(nav) {
                margin-left: 0;
            }
        }
    }

    .company-info {
        margin-bottom: -25px;

        @include media(nav) {
            display: none;
        }
        a {
            font-size: 14px;
            padding: 8px 12px;
            display: inline-block;
            transition: opacity 0.3s ease;
            text-transform: uppercase;
            color: white;
            font-family: $font-noto;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.05em;

            + a {
                margin-left: 0.5rem;
            }
            svg {
                width: 0.625rem;
                height: 0.625rem;
            }
        }

        &__email {
            background: colour(black);

            &:hover {
                color: colour(red);
            }
        }

        &__number {
            background: colour(red);
            color: colour(black) !important;

            &:hover {
                background: colour(theme-blue);
                color: colour(white) !important;
            }
        }
    }

    @include media(nav) {
        // Need a specific break point to prevent nav breakage
        .hamburger {
            display: inline-block;
        }

        nav#primary {
            display: none;
        }
    }
}

.header-search {
    display: none;
    width: 150%;
    place-items: center;
    grid-template-columns: 1fr 30px;
    position: absolute;
    top: -9px;
    right: -4px;

    @include media(nav) {
        display: none !important;
    }

    .search-open & {
        display: grid;
    }

    &__field {
        margin: 0 !important;
        grid-column: 1/-1;
        grid-row: 1;
        border-radius: 3px;
    }

    &__submit {
        grid-column: 2;
        grid-row: 1;
    }
}

.subnav {
    padding: 1.875rem;
    display: none;
    position: absolute;
    width: 100%;
    max-width: 1320px;
    top: 100%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 100;
    background-color: colour(light_cream);
    box-shadow: 0 7px 13px rgba(0, 0, 0, 0.15);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: url('/assets/img/accents/bg-texture-cream.jpg') center
            repeat;
        opacity: 0.2;
        z-index: 0;
        background-size: cover;
    }

    a {
        text-transform: initial;
        letter-spacing: 0;
    }

    &.is-active {
        display: block;
    }

    h2 {
        @extend .h3;
        color: black;
    }

    .column__split {
        display: flex;

        > * {
            flex-basis: 100%;
        }

        aside {
            flex-basis: 32%;
        }
    }

    aside {
        padding: 0 0 0 3rem;
        @include media(md) {
            display: none;
        }

        a {
            color: colour(black);
            font-weight: 400;

            &:hover {
                color: $link-hover;
            }
        }

        h3 {
            @extend .h4;
            margin-bottom: 3rem;
            margin-top: 1.063rem;
            font-family: $font-alt;
            letter-spacing: 1px;
            font-size: 1.875rem;
            line-height: 1.1;
            text-transform: uppercase;
        }

        ul {
            li {
                &:not(:last-child) {
                    margin: 1.75rem 0;
                }

                &.is-active {
                    a {
                        color: colour(teal);
                    }
                }

                a {
                    font-family: $font-main;
                }
            }
        }
    }

    &__links {
        aside {
            h3 {
                margin-bottom: 1rem;
                font-family: $font-main;
                font-weight: 600;
                font-size: 1.875rem;
                line-height: 2.188rem;
                text-transform: uppercase;
                letter-spacing: 1px;
            }
        }

        .nav__groups {
            display: grid;
            gap: 50px;
            grid-template-columns: repeat(4, 1fr);

            > * {
                // width: calc(100% / 4);
                margin-bottom: 2.5rem;
                break-inside: avoid;
            }

            a {
                color: colour(black);
                font-family: $font-main;
                font-size: 0.875rem;
                line-height: 1.25rem;
                font-weight: 400;

                &:hover {
                    color: $link-hover;
                    text-decoration: underline;
                }
            }

            h5 {
                margin-bottom: 1.5rem;
                line-height: 1.1;
                a {
                    font-family: $font-alt;
                    font-size: 1.88rem;
                    font-weight: 600;
                    line-height: 1.18;
                    color: colour(theme-blue);
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
            }

            ul {
                li {
                    &:not(:last-child) {
                        margin: 0.3rem 0;
                    }
                }
            }
        }

        .nav__break {
            flex-basis: 100%;
            width: 0;
            margin: 0;
        }
    }

    &__resource {
        .tiles {
            &__headline {
                margin-bottom: 1.5rem;
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                align-content: center;
                align-items: center;

                h2 {
                    font-size: 4.438rem;
                    line-height: 4.25rem;
                    color: colour(theme-blue);
                }

                a {
                    display: inline-block;
                    color: colour(black);
                    margin-right: 0;
                    text-transform: uppercase;
                    padding-left: 15px;
                    padding-right: 15px;

                    &:hover {
                        color: colour(white);
                    }
                }

                > * {
                    flex-basis: unset;
                }
            }

            .tile {
                min-width: auto;

                h3 {
                    font-family: $font-main;
                    font-size: 1.25rem;
                    line-height: 25px;
                    letter-spacing: 1px;
                    color: colour(black);
                    text-transform: uppercase;
                    font-weight: 600;
                }

                .subtext {
                    font-size: 0.875rem;
                    line-height: 1rem;
                    color: colour(teal);
                    letter-spacing: 0.05em;
                    font-family: $font-noto;
                    font-weight: 600;
                }
            }
        }
    }

    .featuredProducts {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        .product {
            margin-bottom: 2rem;

            a {
                display: block;
            }

            a:focus {
                outline: 2px dashed colour(teal);
                outline-offset: 5px;
            }

            &__content {
                h5 {
                    font-family: $font-main;
                    font-size: 1.25rem;
                    line-height: 1.563rem;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    margin-top: 10px;
                }

                .subtext {
                    color: colour(black);
                    font-family: $font-alt;
                    font-size: 0.75rem;
                    line-height: 0.875rem;
                    margin: 0.25rem 0;
                }
            }
        }
    }
    .tiles {
        justify-content: flex-start;

        .tile__content {
            .subtext {
                margin: 25px 0 10px 0;
            }

            p {
                text-transform: initial;
            }
        }

        &__sports {
            .tile {
                flex-basis: calc((100% / 4) - 4em);
                margin: 1em 2em;
                text-align: center;

                &__image {
                    border: 1px solid #424242;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                }

                &__content {
                    h3 {
                        font-family: $font-header;
                        font-size: clamp-sizes(1.65, 1.875);
                        letter-spacing: 2px;
                        margin: 0.5rem 0 0;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

//------------------------------------
// Mobile Nav
//------------------------------------
.nav-is-open {
    .hamburger {
        &::before {
            transform: translateY(7px) rotate(135deg);
        }

        &::after {
            transform: translateY(-7px) rotate(-135deg);
        }

        > div {
            transform: scale(0);
        }
    }
}

.mobile-action {
    display: flex;
    justify-content: flex-end;
}

.mobile-nav-overlay {
    display: none;
    position: fixed;
    top: 5.6875rem;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    padding: 0 0 1rem 0;
    background-color: white;
    z-index: 5;

    .nav-is-open & {
        @include media(nav) {
            display: block;
        }
    }

    .company-info {
        a {
            font-size: 1.125rem;
            padding: 1rem;
            width: 100%;
            text-align: center;
            transition: opacity 0.3s ease;
            font-family: $font-alt;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                opacity: 0.7;
            }

            svg {
                width: 1.25rem;
                height: 0.75rem;
                margin: 0.125rem 0.3125rem 0 0;
            }
        }

        &__email {
            color: colour(teal);
        }

        &__number {
            background: colour(teal);
            color: white;
        }
    }
}

.mobile-search {
    padding: 0 1rem;

    form {
        display: flex;
        align-items: center;
        background-color: colour(grey);
    }

    input {
        margin: 0;
    }

    button {
        width: 3.125rem;
        height: 3.21875rem;
        background-color: colour(grey);
    }
}

.mobile-nav {
    margin: 2rem 0;
    padding: 0 1rem;

    &__list {
        border-top: 1px solid colour(grey);
        max-height: calc(100vh - 16rem);
        overflow: auto;
    }

    &__title,
    a {
        color: colour(black);
        &:hover,
        &:active {
            color: colour(teal);
        }
    }

    &__title.is-active,
    &__title.is-active a {
        color: colour(teal);
    }

    button.mobile-nav__title,
    p.mobile-nav__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 1rem 0;
        border-bottom: 1px solid colour(grey);
        font-family: $font-header;
        font-size: 1.25rem;
        letter-spacing: 0.5px;
        cursor: pointer;

        .icon {
            transform: rotate(180deg);
        }

        &.is-open {
            .icon {
                transform: rotate(0);
            }

            & + .mobile-sub-nav {
                display: block;
            }
        }
    }

    .is-active {
        color: colouur(red);
    }
}

.mobile-sub-nav {
    display: none;
    padding: 1rem 0;
    border-bottom: 1px solid colour(grey);

    &__item {
        font-size: 0.875rem;
    }

    &__item + &__item {
        margin-top: 1.875rem;
    }
}

//------------------------------------
// Checkout Header
//------------------------------------
.checkout-header {
    padding: 0.625rem 0;
    background-color: black;
    color: white;

    .logo svg {
        width: 100%;
    }

    .logo .cover {
        fill: colour(teal);
    }

    &__contact {
        text-align: right;

        b {
            font-size: 1.25rem;

            a {
                font-family: inherit;
                font-size: 1.25rem;
                font-weight: 700;
                transition: color 200ms ease;

                &:hover {
                    color: white;
                }
            }
        }
    }
}

//------------------------------------
// Site Humphrys
//------------------------------------

.site-humphrys {
    .site-header {
        background: white;

        .logo {
            display: flex; // Remove white space artifact
            width: 90px;
            aspect-ratio: 1/1;

            @include media(nav) {
                width: 70px;
            }

            svg {
                height: 100%;
                width: auto;
            }
        }

        .-static {
            gap: 4rem;
            padding-top: 20px;
            padding-bottom: 20px;

            @include media(nav) {
                padding-top: 0px;
                padding-bottom: 0px;
            }
        }

        .company-info {
            position: relative;
            z-index: 10;

            a {
                font-size: 12px;
                line-height: 13.2px;
            }
            &__email {
                font-family: $humphrys-font-content;
                background: transparent;
                color: $humphrys-link;
                font-weight: 500;

                &:hover {
                    color: $humphrys-link-hover;
                }
            }

            &__number {
                font-family: $humphrys-font-content;
                background: $humphrys-yellow;
                color: black;
                font-weight: 500;

                &:hover {
                    background: $humphrys-button-primary-hover;
                    color: black !important;
                }
            }
        }

        nav#primary {
            .primary-nav-list {
                .primary-nav-item {
                    &.is-active {
                        .primary-nav-link {
                            color: $humphrys-yellow;

                            &.has-subnav:after {
                                background-image: url(/assets/img/icons/icon-arrow-drop-yellow.svg);
                            }
                        }
                    }

                    &.has-subnav {
                        &:hover,
                        &:focus,
                        &:focus-within {
                            .primary-nav-link {
                                color: $humphrys-yellow;

                                &.has-subnav:after {
                                    background-image: url(/assets/img/icons/icon-arrow-drop-yellow.svg);
                                    transform: rotate(180deg);
                                }
                            }

                            .subnav {
                                display: block;
                            }
                        }
                    }
                }

                .primary-nav-link {
                    font-family: $humphrys-font-content;
                    color: black;
                    font-size: 18px;
                    line-height: 19.8px;
                    font-weight: 500;
                    text-transform: capitalize;

                    &.has-subnav:after {
                        content: '';
                        height: 12px;
                        width: 12px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        background-image: url(/assets/img/icons/icon-arrow-drop-black.svg);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 12px;
                        margin-left: 10px;
                        transition: 0.3s ease all;
                    }

                    &:hover {
                        color: $humphrys-yellow;
                    }
                }
            }
        }

        .user-info {
            margin: 0;

            &__cart {
                .indicator {
                    background: $humphrys-yellow;
                    color: black;
                }
            }
        }

        .hamburger {
            &::after,
            &::before,
            > div {
                background-color: black;
            }
        }

        .subnav {
            display: none;
            max-width: 991px;
            top: 85%;
            left: unset;
            right: unset;
            background-color: colour(grey);
            border: 2px solid colour(grey);
            border-radius: 16px;
            width: auto;
            min-width: auto;
            padding: 30px 20px;
            margin-right: 15px;

            &:before {
                content: '';
                position: absolute;
                top: -25px;
                left: 30px;
                height: 12px;
                width: 25px;
                background-image: url(/assets/img/icons/humphrys-menu-triangle.svg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                top: -13px;
                opacity: 1;
                z-index: 100;
            }

            &__links {
                .column__split {
                    display: block;
                }

                .nav {
                    &__groups {
                        display: grid;
                        grid-template-columns: auto auto auto;
                        gap: 40px 20px;

                        > div {
                            width: 100%;
                            margin: 0;
                        }

                        h5 {
                            margin-bottom: 8px;

                            a {
                                font-size: 16px;
                                color: black;
                                font-weight: 500;
                                font-family: $humphrys-font-content;
                                line-height: 24px;
                                text-transform: unset;

                                &:hover {
                                    color: $humphrys-yellow;
                                    text-decoration: none;
                                }
                            }
                        }

                        ul {
                            li {
                                margin: 0;

                                a {
                                    padding: 12px 15px 12px 51px;
                                    border-radius: 12px;
                                    overflow: hidden;
                                    position: relative;

                                    display: flex;
                                    font-family: $humphrys-font-content;
                                    transition: 0.3s ease background-color;

                                    > div {
                                        display: flex;
                                        flex-direction: column;
                                        gap: 2px;

                                        p {
                                            font-family: $humphrys-font-content;
                                            font-size: 16px;
                                            line-height: 24px;
                                            font-weight: 500;
                                            margin: 0;
                                            transition: 0.3s ease color;
                                        }

                                        span {
                                            font-size: 14px;
                                            line-height: 20px;
                                            font-weight: 400;
                                        }
                                    }

                                    &:hover {
                                        text-decoration: none;
                                        color: colour(black);
                                        background: colour(light-grey);

                                        p {
                                            color: $humphrys-yellow;
                                        }
                                    }

                                    &:before {
                                        content: '';
                                        background-image: url(/assets/img/icons/icon-store.svg);
                                        height: 24px;
                                        width: 24px;
                                        position: absolute;
                                        left: 15px;
                                        top: 12px;
                                        background-size: cover;
                                        background-position: center;
                                    }
                                }

                                &:not(:last-child) {
                                    margin-top: 0;
                                    margin-bottom: 12px;
                                }
                            }
                        }
                    }
                }
            }

            @media only screen and (max-width: 1300px) {
                &__links {
                    .nav {
                        &__groups {
                            grid-template-columns: auto auto;
                        }
                    }
                }
            }
        }

        .company-info {
            margin-bottom: -29px;

            &__email {
                color: black;
                &:hover {
                    color: $humphrys-yellow;
                }
            }
        }

        .white-focus:focus {
            @extend %dashed-focus;
            outline-color: colour(theme-blue);
        }
        &.site-humphrys-hero-overlay {
            background: transparent;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 99;
    
            .company-info {
                a {
                    &:focus {
                        outline-color: white;
                    }
                }
    
                &__email {
                    color: white;
                    &:hover {
                        color: $humphrys-yellow;
                    }
                }
            }
    
            nav#primary {
                .primary-nav-list {
                    .primary-nav-item {
                        &.is-active {
                            .primary-nav-link {
                                color: $humphrys-yellow;
                            }
                        }
                    }
    
                    .primary-nav-link {
                        color: white;
    
                        &:hover {
                            color: $humphrys-yellow;
    
                            &.has-subnav:after {
                                background-image: url(/assets/img/icons/icon-arrow-drop-yellow.svg);
                            }
                        }
    
                        &.has-subnav:after {
                            background-image: url(/assets/img/icons/icon-arrow-drop-white.svg);
                        }
                    }
                }
            }
    
            .hamburger {
                &::after,
                &::before,
                > div {
                    background-color: white;
                }
            }
    
            .white-focus:focus {
                @extend %dashed-focus;
                outline-color: white;
            }
        }
    }
    

    .mobile-nav {
        button.mobile-nav__title,
        p.mobile-nav__title {
            font-family: $humphrys-font-content;

            a {
                color: black;
            }

            &.is-active {
                color: $humphrys-yellow;

                a {
                    color: $humphrys-yellow;
                }
            }
        }

        .mobile-nav__title {
            &.has-subnav {
                .icon {
                    height: 20px;
                    width: 20px;
                    background-image: url(/assets/img/icons/icon-arrow-drop-black.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 16px;
                    transform: rotate(0);
                }

                &.is-open {
                    .icon {
                        transform: rotate(180deg);
                        background-image: url(/assets/img/icons/icon-arrow-drop-yellow.svg);
                    }
                }
                &.is-active,
                &:hover {
                    .icon {
                        background-image: url(/assets/img/icons/icon-arrow-drop-yellow.svg);
                    }
                }
            }
        }

        &__item {
            a,
            button {
                font-weight: 500;
                &:hover {
                    color: $humphrys-yellow;
                }
            }
        }

        &-overlay {
            .company-info {
                a {
                    font-family: $humphrys-font-content;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;

                    &:hover {
                        opacity: 0.7;
                    }
                }

                &__email {
                    color: $humphrys-dark-blue;
                }

                &__number {
                    background: $humphrys-dark-blue;
                    color: white;
                }
            }
        }
    }

    .mobile-search {
        input {
            font-family: $humphrys-font-content;
        }
    }
}

//------------------------------------
// Site Metflag
//------------------------------------
.site-metflag {
    .site-header {
        .logo {
            &--desktop {
                display: block;
                height: 112px;
                position: relative;
                &--default,
                &--hover {
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform: opacity 0.3s ease;
                }
                &--default {
                    z-index: 2;
                    opacity: 1;
                }
                &--hover {
                    z-index: 1;
                    opacity: 0;
                    top: -1px;
                    left: -1px;
                }
                @include media(nav) {
                    display: none;
                }
            }
            &:hover,
            &:focus {
                .logo--desktop {
                    &--default {
                        z-index: 1;
                        opacity: 0;
                    }
                    &--hover {
                        z-index: 2;
                        opacity: 1;
                    }
                }
            }
        }
    }
}