.tabs {
    display: flex;
    justify-content: center;
    box-shadow: 0px 21px 0px -15px #f0f0f8;
    margin-bottom: 4rem;

    @include media(md) {
        display: none;
    }

    > * {
        flex: 1;
    }

    .tab {
        border: 1px solid colour(grey);
        color: colour(dark-grey);
        font-family: $font-header;
        font-size: 1.125rem;
        padding: 1rem;
        text-align: center;
        transition: 0.2s ease all;

        &:hover {
            color: colour(white);
            background: colour(teal);
        }

        &.is-active {
            color: colour(white);
            background: colour(teal);

            &:hover {
                cursor: auto;
            }
        }
    }

    &__content {
        .tab__content {
            // Hide show all the tab content only on screens larger than medium
            @include media(md, min-width) {
                display: none;

                &.is-active {
                    display: block;
                }
            }

            // When some tab content is active on medium and smaller
            // screens, show everything after the tab heading
            &.is-active {
                @include media(md) {
                    .tab__heading ~ * {
                        display: block;
                    }

                    .tab__heading svg {
                        transform: translateY(-40%) rotate(0);
                    }
                }
            }
        }

        .tab__toggle {
            width: 100%;
        }

        .tab__heading {
            position: relative;
            display: none;
            margin: 0;
            padding: 1.25rem 0;
            font-size: clamp-sizes(1.5, 2.5, 380, 850);
            cursor: pointer;

            svg {
                content: url('/assets/img/icons/select-arrow.svg');
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-40%) rotate(180deg);
                transition: transform 200ms ease;
            }

            @include media(md) {
                display: block;

                // Hide everything in tab content except the
                // heading on medium and smaller screens
                ~ * {
                    display: none;
                }
            }
        }

        .tab__inner {
            @include media(md) {
                padding-bottom: $gutter;
            }
        }
    }
}

//------------------------------------
// Site Humphrys
//------------------------------------

.site-humphrys,
#product.site-humphrys {
    .tab {
        &__inner {
            background: #fff;
            padding: 20px;
            border-radius: 0;
            border: 1px solid #ededed;

            &.multiColumn {
                width: 100%;
                max-width: 100%;
            }

            .product-tech-details {
                padding: 0;
            }

            h3 {
                font-family: $humphrys-font-heading;
                font-size: 36px;
                font-weight: 600;
                line-height: 40px;
                color: black;
                margin-bottom: 20px;
                text-transform: capitalize;
            }

            h4 {
                font-family: $humphrys-font-content;
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                color: black;
                margin-bottom: 10px;
            }

            p {
                font-family: $humphrys-font-content;
                font-size: 1.125rem;
                font-weight: 400;
                line-height: 20px;
                color: black;
            }
        }

        &__content {
            &.tab-1 {
                &.is-active {
                    .tab {
                        &__table {
                            padding: 20px;

                            .table {
                                margin: 0;
                            }
                        }
                    }
                }
            }

            &.tab-techdetails {
                &.is-active {
                    .tab {
                        &__inner {
                            padding-top: 0;

                            h3 {
                                font-family: $humphrys-font-heading;
                                font-size: 24px;
                                font-weight: 600;
                                line-height: 30px;
                                color: black;
                                margin-bottom: 10px;
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }

            .tab {
                &__heading {
                    padding: 0;
                }

                &__toggle {
                    background: #fff;
                    font-size: 1.125rem;
                    font-weight: 700;
                    line-height: 20px;
                    color: #000;
                    padding: 20px;
                    font-family: $humphrys-font-content;
                    border-radius: 0;
                    text-transform: capitalize;

                    &:first-child {
                        border-radius: 12px 12px 0 0;
                    }
                }
            }

            &.is-active {
                button {
                    &.tab__toggle {
                        background: $humphrys-dark-blue;
                        color: #fff;
                        border-radius: 0;
                        font-family: $humphrys-font-content;
                    }
                }

                &:first-child {
                    button {
                        &.tab__toggle {
                            border-radius: 12px 12px 0 0;
                        }
                    }
                }

                &:last-child {
                    .tab__inner {
                        border-radius: 0 0 12px 12px;
                    }
                }
            }

            &:not(.is-active) {
                button {
                    &.tab__toggle {
                        border-radius: 0;
                        border: 1px solid #ededed;
                        border-top: none;
                    }
                }

                &:first-child {
                    button {
                        &.tab__toggle {
                            border-radius: 12px 12px 0 0;
                        }
                    }
                }

                &:last-child {
                    button {
                        &.tab__toggle {
                            border-radius: 0 0 12px 12px;
                        }
                    }
                }
            }
        }
    }

    .tabs {
        box-shadow: none;
        margin-bottom: 0;

        .tab {
            font-family: $humphrys-font-content;
            font-size: 1.125rem;
            font-weight: 700;
            line-height: 20px;
            color: $plain-text;
            background: colour(white);
            border-right: 1px solid #ededed;
            padding: 20px;
            border-bottom: none;

            &:first-child {
                border-radius: 12px 0 0 0;
            }
            &:last-child {
                border-radius: 0 12px 0 0;
            }

            &:hover {
                color: colour(white);
                background: $humphrys-dark-blue;
            }

            &.is-active {
                color: colour(white);
                background: $humphrys-dark-blue;

                &:hover {
                    cursor: auto;
                }
            }
        }

        &__content {
            table {
                table-layout: fixed;
                min-width: 100%;
                border: none;

                thead {
                    background-color: colour(black);

                    th {
                        font-family: $humphrys-font-content;
                        font-size: 1.125rem;
                        font-weight: 700;
                        line-height: 20px;
                        padding: 2rem 1.25rem;
                        color: white;
                        border-right: 1px solid #ededed;
                        text-align: center;
                    }

                    th:first-child {
                        text-align: left;
                    }

                    th:last-child {
                        border-right: none;
                    }
                }

                tbody {
                    font-family: $humphrys-font-content;
                    font-size: 1.125rem;
                    font-weight: 400;
                    line-height: 20px;
                    display: table-row-group;

                    td:first-child {
                        text-align: left;
                        font-weight: 700;
                        padding: 2rem 1.25rem;
                    }

                    td {
                        padding: 2rem;
                        text-align: center;
                        border-left: 1px solid #ededed;
                        font-family: $humphrys-font-content;
                        font-size: 1.125rem;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: center;

                        p {
                            font-family: $humphrys-font-content;
                            font-size: 1.125rem;
                            font-weight: 400;
                            line-height: 20px;
                            text-align: center;
                        }
                    }

                    tr {
                        display: table-row;
                        &:nth-child(odd) {
                            background: colour(white);
                        }

                        &:nth-child(even) {
                            background: #ededed;
                            border-top: 1px solid #ededed;
                            border-bottom: 1px solid #ededed;
                        }
                    }
                }
            }
        }
    }
}
