.multiColumn {
    .columns {
        &__two {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            & > div {
                width: 50%;
            }

            @media (max-width: 800px) {
                flex-direction: column;

                & > div {
                    width: 100%;
                }
            }
        }

        ul {
            li {
                padding: 0;
            }
        }
    }

    h2 {
        font-size: clamp(34px, 5vw + 1rem, 71px);
        font-weight: 400;
        line-height: 67.45px;
        letter-spacing: 1px;
        text-align: left;
        color: colour(teal);
        margin-bottom: 30px;
    }
    p {
        font-family: $font-main;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: black;
    }

    h3 {
        font-family: $font-handwritten;
        font-size: clamp(20px, 5vw + 1rem, 30px);
        font-weight: 600;
        line-height: 35px;
        letter-spacing: 1px;
        text-align: left;
        text-transform: uppercase;
        color: colour(teal);
        margin-bottom: 30px;
    }

    ul {
        list-style: none;
        padding-left: 0;

        li {
            position: relative;
            font-family: $font-main;
            font-size: 24px;
            font-weight: 400;
            line-height: 30px;
            text-align: left;
            padding: 0;
            margin-bottom: 20px;

            &::marker {
                content: none;
                display: inline-block;
                vertical-align: middle;
            }
            &::before {
                position: absolute;
                content: url('/assets/img/icons/checkmark.svg');
                top: 50%;
                left: -1.5rem;
                transform: translate(-50%, -50%);
            }
        }
    }
}

//------------------------------------
// Site Humphrys
//------------------------------------

.site-humphrys {
    &#about-us {
        .section {
            &-multiColumn {
                background: white;
                margin: 0;
                padding: 144px 0;

                @include media(md) {
                    padding: 60px 0;
                }
            }
        }
    }

    .section {
        &-multiColumn {
            margin: 0;
            padding: 80px 0;

            .multiColumn {
                .columns {
                    margin: 0 -25px;
                    align-items: center;

                    > * {
                        margin: 0 25px;
                    }

                    h2 {
                        font-size: 48px;
                        line-height: 48px;
                        color: black;
                        font-weight: 600;
                        font-family: $humphrys-font-heading;
                        text-transform: unset;
                        margin-bottom: 15px;
                    }

                    p {
                        font-family: $humphrys-font-poppins;
                        font-size: 16px;
                        line-height: 22px;
                        color: black;
                        font-weight: 400;
                    }

                    h3 {
                        font-size: 36px;
                        line-height: 40px;
                        font-weight: 600;
                        color: black;
                        font-family: $humphrys-font-heading;
                        text-transform: unset;
                        margin-bottom: 14px;
                    }

                    ul {
                        li {
                            font-size: 20px;
                            line-height: 22px;
                            font-weight: 700;
                            font-family: $humphrys-font-content;
                            margin-bottom: 17px;
                            color: black;

                            &::before {
                                content: url(/assets/img/icons/check-mark-dark-blue.svg);
                            }
                        }
                    }

                    @include media(lg) {
                        h2 {
                            font-size: 36px;
                            line-height: 36px;
                        }
                        h3 {
                            font-size: 30px;
                            line-height: 36px;
                        }
                    }
                    @include media(md) {
                        margin: 0;
                        row-gap: 35px;

                        > * {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
