.icon-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.25rem 1rem;
    text-align: center;

    @include media(sm) {
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0;
        text-align: left;
    }

    &__heading {
        margin: 1.25rem 0 0;
        font-size: 1.125rem;
    }

    p.icon-card__copy {
        margin: 0.5rem 0 1rem 0;
    }

    .arrow-link {
        color: colour(red);
        margin-top: auto;

        @include media(sm) {
            margin-top: 0;
            margin-left: auto;
        }
    }
}
