:root {
    --table-border: 1px solid rgba(0, 0, 0, 0.1);
}

.table {
    margin-bottom: 1rem;
    overflow-x: auto;
}

table {
    table-layout: fixed;
    min-width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    border: var(--table-border);
    text-align: left;

    td,
    th {
        min-width: 180px;
    }

    th + th,
    td + td {
        border-left: var(--table-border);

        @include media(md) {
            border-left: none;
        }
    }

    thead {
        background-color: colour(black);

        th {
            padding: 2rem 1rem;
            color: white;
            font-size: 1.125rem;
        }

        th:first-child {
            text-align: left;
        }
    }

    tbody {
        @include media(md) {
            display: block;
        }

        td:first-child {
            text-align: left;
            font-weight: 800;
        }

        td {
            padding: 2rem;

            @include media(md) {
                padding: 1rem;
            }

            img {
                width: 100%;
            }
        }

        tr {
            @include media(md) {
                display: flex;
                flex-direction: column;
            }

            &:nth-child(odd) {
                background: rgba(0, 0, 0, 0.05);
            }

            &:nth-child(even) {
                background: rgba(0, 0, 0, 0.1);
                border-top: var(--table-border);
                border-bottom: var(--table-border);
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
}
