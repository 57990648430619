.featuredResources {
  .tiles {
    &__headline {
      h2 {
        font-size: clamp(50px, 5vw + 1rem, 71px);
        font-weight: 400;
        line-height: 67.45px;
        letter-spacing: 1px;
        text-align: center;
        color: colour(teal);
        margin-bottom: 37px;
      }
    }

    .tile {
      &__content {

        .subtext {
          font-family: $font-noto;
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: 0.05em;
          text-align: left;
          color: colour(red);
          text-transform: uppercase;
          margin-top: 25px;
          margin-bottom: 10px;
        }

        h3 {
          font-family: $font-handwritten;
          font-size: 20px;
          font-weight: 600;
          line-height: 25px;
          letter-spacing: 1px;
          text-align: left;
        }
      }
    }
  }
}
