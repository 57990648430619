.breadcrumbs {
    font-family: $font-handwritten;
    text-align: left;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 1px;

    a {
        color: colour(black);
        transition: color 200ms ease;

        &:hover {
            color: colour(teal);
        }
    }

    span {
        color: colour(red);

        &:not(.active) {
            margin: 0 0.5rem;
        }
    }

    .active {
        color: colour(slate-gray);
    }
}

//------------------------------------
// Site Humphrys
//------------------------------------

.site-humphrys {
    .breadcrumbs {
        font-family: $humphrys-font-content;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        margin-right: 10px;
        color: #000;
        text-transform: uppercase;

        span {
            color: #736F62;
        }
    }
}