#my-account {
    section {
        &:first-of-type {
            margin-top: 2rem;
        }
    }

    main {
        aside {

            h2 {
                font-family: $font-handwritten;
                font-size: clamp(20px, 5vw + 1rem, 30px);
                font-weight: 600;
                line-height: 35px;
                letter-spacing: 1px;
                text-align: left;
                color: black;
                margin-bottom: 30px;
            }

            @include media(md) {
                padding: 0;
            }

            a {
                font-family: $font-main;
                font-size: 18px;
                font-weight: 400;
                line-height: 18px;
                color: colour(black);
                text-decoration: underline;


                &:hover {
                    color: colour(teal);
                }
            }

            h3 {
                font-family: $font-handwritten;
                font-size: 20px;
                font-weight: 600;
                line-height: 25px;
                letter-spacing: 1px;
                text-align: left;
                margin-bottom: 10px;
                color: black;
            }

            p {
                font-family: $font-main;
                font-size: 24px;
                font-weight: 400;
                line-height: 30px;
                text-align: left;
                color: black;
            }

            > div {
                margin-bottom: 2rem;
            }
        }
    }

    .container {
        &__twoColumn {
            display: flex;
            justify-content: flex-start;
            gap: 4rem;
            @include media(md) {
                flex-wrap: wrap;
                flex-direction: column-reverse;
            }

            > * {
                &:nth-child(1) {
                    flex-basis: 75%;
                    @include media(md) {
                        flex-basis: 100%;
                    }
                }

                &:nth-child(2) {
                    flex-basis: 25%;
                    @include media(md) {
                        flex-basis: 100%;
                    }
                }
            }
        }
    }
}

//------------------------------------
// Site Humphrys
//------------------------------------

#my-account {
    &.site-humphrys {
        #main-content {
            p, a {
                font-family: $humphrys-font-content;
                font-size: 1rem;
                line-height: 1.25rem;
            }

            .plain-link, aside a {
                color: $humphrys-yellow;

                &:hover {
                    color: $humphrys-blue;
                }
            }

            .orders__listing, aside {
                h2 {
                    font-family: $humphrys-font-heading;
                    font-size: 36px;
                    line-height: 40px;
                    font-weight: 600;
                    text-transform: unset;
                    text-align: left;
                    color: black;
                    letter-spacing: normal;
                }
            }

            .orders {
                &__listing {
                    h2 {
                        font-family: $humphrys-font-heading;
                        font-size: 36px;
                        line-height: 40px;
                        font-weight: 600;
                        text-transform: unset;
                        text-align: left;
                        color: black;
                        letter-spacing: normal;
                    }
                }

                &__header {
                    padding-left: 0;
                    padding-right: 0;
                    grid-template-columns: 120px 0.75fr 2fr 75px;

                    > * {
                        font-family: $humphrys-font-heading;
                    }
                }

                .order {
                    padding-left: 0;
                    padding-right: 0;
                    grid-template-columns: 120px 0.75fr 2fr 75px;

                    div {
                        font-family: $humphrys-font-content;
                        font-size: 1rem;
                        line-height: 1.25rem;
                    }

                    > * {
                        &:nth-child(4) {
                            font-size: 1.5rem;
                            line-height: 1.4em;
                            text-align: left;
                        }
                    }

                    .tag {
                        font-size: 18px;
                        line-height: 20px;
                        font-family: $humphrys-font-content;
                        font-weight: 500;
                        padding: 10px 5px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $humphrys-dark-blue;
                        color: white;
                        border-color:  $humphrys-dark-blue;

                        &:hover {
                            background-color: $humphrys-blue;
                            border-color:  $humphrys-blue;
                        }
                    }
                }
            }
        }
    }
}