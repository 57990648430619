.form,
.fui-form {
    &__header {
        position: relative;
        margin: 0 0 2rem;

        h2 {
            margin: 0;
        }

        &--flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 0 2rem;
            @include media(md) {
                display: block;
            }

            h2 {
                margin: 0;
            }

            p {
                em {
                    font-style: normal;
                    a {
                        margin-left: 2rem;
                    }
                }
            }
        }
        .card-types {
            position: absolute;
            right: 0;
            left: auto;
            bottom: -20px;
            @include media(md) {
                bottom: -50px;
            }
        }
    }

    label {
        font-family: $font-alt;
        font-size: 20px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 1px;
        text-align: left;
        color: black;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    input, select, textarea {
        margin-top: 0;
        background: white;
        font-family: $font-main;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: black;
        padding: 15px 20px;
    }
    input {
        font-family: $font-main;
        font-size: 16px;
        font-weight: 400;
    }

    &__group,
    .fui-row,
    .fui-alert {
        display: flex;
        align-items: center;
        margin: 1rem -0.5rem;
        @include media(md) {
            flex-wrap: wrap;
        }

        > * {
            flex: 1;
            margin: 0 0.5rem;
            @include media(md) {
                flex-basis: 100%;
            }
        }
    }

    .fui-row {
        align-items: flex-start;
        margin-bottom: 0;
    }

    &__info {
        font-size: 0.875rem;
        color: #7e7e7e;
    }

    &__checkbox {
        label {
            display: flex;
            align-items: center;
            padding: 0.35rem 0;
        }

        input {
            margin: 0 8px 0 0;
        }
    }

    &__navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__submit,
    .fui-btn-container {
        text-align: right;
    }

    &__contact {
        h2 {
            font-size: 1.875rem;
            font-weight: 400;
            line-height: 2rem;
            letter-spacing: 1px;
            text-align: left;
            margin-bottom: 1.875rem;
        }

        .fui {
            &-instructions {
                display: none;
            }

            &-fieldset {
                .fui-row {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            &-row {
                margin-top: 0;
                margin-bottom: 18px;
            }

            &-btn-wrapper {
                width: max-content;
                display: block;
                margin-left: auto;
                margin-right: 0;

                button {
                    padding: 0.438rem 1.25rem;
                    font-size: 1.25rem;
                    font-weight: 700;
                    line-height: 1.5rem;
                    letter-spacing: 0.02em;
                }
            } 
        }
    }
}

.field {
    &__secure {
        position: relative;

        &::after {
            content: '';
            background: url('/assets/img/commerce/secure.svg') center center no-repeat;
            background-size: contain;
            position: absolute;
            right: 12px;
            bottom: 26px;
            width: 21px;
            height: 18px;
        }
    }
}

.formie-recaptcha-placeholder {
    display: none;
}

.fui-required,
.fui-error-message,
.fui-field-error,
.fui-alert-error {
    color: colour(teal);
}

// The "Get a free quote" form in the footer has a teal background, so we make its functional text cream here:
.quote__form {
    .fui-required,
    .fui-error-message,
    .fui-field-error,
    .fui-alert-error {
        color: colour(cream);
    }
    .fui-input,
    .fui-textarea {
        &:focus {
            outline: 2px dashed colour(white);
            outline-offset: 3px;
        }
    }
}

.fui-alert-error {
    color: colour(teal);
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.fui-alert-success {
    font-family: $font-alt;
    font-size: 1.4rem;
    margin-top: 1.5rem;
}

.fui-row-empty {
    display: none !important;
}

.fui-checkbox {
    label {
        margin-left: 0.5rem;
        vertical-align: middle;
        line-height: 1.75;
    }
    input {
        vertical-align: middle;
    }
}

.fui-radio {
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 0.5em;
    input {
        transform: translateY(-0.5em);
    }
}

.fui-layout-wrap {
    margin-bottom: 1.2rem;
}

div.form-note {
    margin-top: 2.2rem;
    font-style: italic;
}

form {
     h2 {
        margin-bottom: 20px;
        font-size: clamp(20px, 5vw + 1rem, 30px);
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 1px;
        text-align: left;
        color: black;
    }

    .form {
        &__item {
            input, select {
                margin-top: 0;
                background: white;
                font-family: $font-main;
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                color: black;
                padding: 15px 20px;
            }
            input {
                font-family: $font-main;
                font-size: 16px;
                font-weight: 400;
            }
        }

        &__item, &__checkbox {
            label {
                font-family: $font-alt;
                font-size: 20px;
                font-weight: 600;
                line-height: 25px;
                letter-spacing: 1px;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 0;
            }
        }

        &__group {
            input {
                &[type=checkbox] {
                    background: white;
                }
            }
        }
    }
}
footer {
    form {
       .form {
           &__item {
               label, .fui-required {
                color: white;
               }

               label {
                margin-bottom: 5px;
               }
           }
        }
    }
}

.site-humphrys {
    .fui {
        &-alert {
            &-success {
                color: #45b157;
            }
    
            &-error {
                color: #f44336;
            }
    
            p {
                font-family: $humphrys-font-content;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 15px;
            }
        }
    
        &-error {
            &-message {
                font-family: $humphrys-font-content;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #f44336;
            }
        }
    }

    form {
        p {
            font-family: $humphrys-font-content;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;

        }

        h2 {
            font-family: $humphrys-font-heading;
            font-size: 36px;
            line-height: 40px;
            font-weight: 600;
            text-transform: unset;
            text-align: left;
            color: black;
            letter-spacing: normal;
        }

   
        .form {
            &__item {
                input, select {
                    margin-top: 0;
                    background: white;
                    font-family: $font-main;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                    color: black;
                    padding: 15px 20px;
                }
                input {
                    font-family: $font-main;
                    font-size: 16px;
                    font-weight: 400;
                }

                a {
                    color: $humphrys-yellow;
                    font-family: $humphrys-font-content;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;

                    &:hover {
                        color: $humphrys-blue;
                    }
                }
            }
    
            &__item, &__checkbox {
                label {
                    font-family: $font-alt;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 25px;
                    letter-spacing: 1px;
                    text-align: left;
                    text-transform: uppercase;
                    margin-bottom: 0;
                }
            }

            &__item {
                label {
                    font-family: $humphrys-font-button;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 1.1;
                    color: colour(black);

                    @media (max-width: 991px) {
                        font-size: 14px;
                    }
                    
                    .fui-required {
                        color: colour(black);
                    }

                    input, select {
                        font-family: $humphrys-font-button;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 1.15;
                        padding: 20px;
                        margin-bottom: 1rem;
                        margin-top: 5px;

                        &:focus {
                            outline: 2px dashed $humphrys-blue;
                            outline-offset: 3px;
                        }
                    }
                }
            }
        }
   }

   .form__contact {
        .fui {
            &-label {
                font-family: $humphrys-font-content;
            }

            &-btn-wrapper {
                .fui-btn {
                    color: colour(black);
                    border: none;
                    border-bottom: 1px solid colour(black);
                    
                    &:after {
                        background-image: url(/assets/img/icons/icon-arrow-black.svg);
                    }
                    
                    &:hover {
                        color: $humphrys-dark-blue !important;
                        border-color: $humphrys-dark-blue !important;
                        
                        &:after {
                            background-image: url(/assets/img/icons/icon-arrow-dark-blue.svg);
                        }
                    }

                    &:focus {
                        outline: 2px dashed $humphrys-blue;
                        outline-offset: 3px;
                    }
                }
            }
        }
    }   

    footer {
        &.primary {
            .footer__contact {
                .button {
                    display: flex;
                }
            }
        }
    }
}