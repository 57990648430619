.modal-blocker {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.5);
    z-index: 100;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: 200ms;

    .modal-is-open & {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: calc(100% - 3rem);
    max-width: 1000px;
    max-height: 90vh;
    padding: 2rem;
    background-color: white;
    transform: translate(-50%, -50%);
    z-index: 100;
    overflow: auto;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: 200ms;

    // Open State
    &[data-open] {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
    }

    &__header {
        background: transparent;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 1.25rem;

        h2,
        h3 {
            margin: 0;
        }
    }

    &__close {
        background-image: url('/assets/img/commerce/close-orange.svg');
        height: 40px;
        width: 40px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        transition: all 0.2s ease;

        &:hover {
            background-image: url('/assets/img/commerce/close-blue.svg');
        }
    }

    &.product-quote-modal {
        background-color: #f1eee9;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: url('/assets/img/accents/bg-texture-cream.jpg') center
                repeat;
            opacity: 0.2;
            z-index: -1;
        }
    }

    .fui {
        &-label {
            margin-bottom: 5px;
        }

        &-instructions {
            margin-bottom: 10px;
        }

        &-btn-wrapper {
            background-color: transparent;
            box-shadow: unset;
            cursor: default;
        }
    }

    &.product-quote-modal,
    &.product-review-modal {
        max-width: 440px;

        .fui-btn-wrapper {
            width: fit-content;
        }
    }
}

.site-humphrys {
    .modal {
        &__close {
            background-image: url('/assets/img/commerce/close-humphrys-yellow.svg');

            &:hover {
                background-image: url('/assets/img/commerce/close-humphrys-blue.svg');
            }
        }

        .fui-btn-wrapper {
            .fui-btn {
                color: $humphrys-dark-blue;

                &:not(:hover):after {
                    background-image: url(/assets/img/icons/icon-arrow-dark-blue.svg);
                }
            }
        }
    }
}
