#product-listing {
    main {
        position: relative;
    }

    .products-listing-wrapper {
        align-items: flex-start;
        gap: 4rem;
        position: unset;

        @include media(md) {
            display: block;
        }
    }

    .pagination {
        align-items: end;

        &__number {
            color: black;
            font-family: $font-main;
            flex: 1;
            text-align: center;
            font-size: 1rem;
            font-weight: 600;
            line-height: 20px;
            margin-bottom: 88px;
        }
    }

    .hero {
        .container {
            padding: 0;
        }
    }

    .filter-toggle {
        display: none;
        @include media(md) {
            display: block;
        }
    }

    .sidebar {
        flex-basis: 20%;
        padding: 0;

        @include media(md) {
            display: none;
            position: absolute;
            background: rgba(0, 0, 0, 0.75);
            top: 0;
            left: 0;
            right: 0;
            bottom: -32px;
            z-index: 2;
        }

        &__close {
            display: none;
            @include media(md) {
                display: block;
            }
        }

        h1 {
            font-family: $font-alt;
            font-size: 20px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 1px;
            text-align: left;
            text-transform: uppercase;
            color: black;
        }

        .hero {
            &__simple {
                @include media(md) {
                    display: none;
                }
            }
        }

        .filters {
            position: relative;

            @include media(md) {
                background: white;
                width: calc(100% - 2.8rem);
                padding: 0 1rem 1rem;
                height: 100%;
                overflow: scroll;
            }

            h2 {
                display: inline-block;
                padding: 1rem 0 45px;
                margin: 0;
                font-size: 50px;
                font-weight: 400;
                line-height: 65px;
                text-align: left;
                color: colour(teal);
            }
        }

        .form__group {
            border-top: 2px solid black;
            padding: 20px 0;
            margin: 0;
            width: 100%;

            h3 {
                display: inline-block;
                margin-bottom: 21px;
                font-family: $font-alt;
                font-size: clamp(20px, 5vw + 1rem, 30px);
                font-weight: 600;
                line-height: 35px;
                letter-spacing: 1px;
                text-align: left;
                text-transform: uppercase;
                color: black;
            }

            label {
                padding: 7px 0;
                width: 100%;
                font-family: $font-alt;
                font-size: 20px;
                font-weight: 600;
                line-height: 25px;
                letter-spacing: 1px;
                text-align: left;
                text-transform: uppercase;
                color: black;
            }
        }

        .form__checkbox {
            margin: 0;

            input {
                background: white;
                @include media(md) {
                    background-color: colour(light_cream);
                }
            }
        }

        .form__submit {
            text-align: left;
        }
    }

    &.sidebar-is-open .sidebar {
        display: block;
    }

    .main {
        flex-basis: 75%;

        section {
            &:first-child {
                margin-bottom: 0;
                @include media(md) {
                    margin-top: 1rem;
                }
            }
        }
        section + section {
            margin-top: 1rem;
        }
    }

    .tiles {
        justify-content: flex-start;

        > * {
            min-width: unset;
        }

        @include media(md) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 1rem;
            width: 100%;
            margin: auto;
        }

        @include media(sm) {
            grid-template-columns: repeat(2, 1fr);
        }

        .tile {
            @include media(md) {
                margin: 0;
            }

            &__content {
                h3 {
                    font-family: $font-alt;
                    font-size: 1.25rem;
                    font-weight: 600;
                    line-height: 25px;
                    letter-spacing: 1px;
                    text-align: left;
                    margin-top: 0;

                    @include media(md) {
                        font-size: 0.875rem;
                    }
                }

                .subtext {
                    @include media(md) {
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }

    .search {
        input {
            margin-top: 0;
            margin-bottom: 0;
            padding: 15px;
            padding-right: 3rem;
            font-family: $font-main;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;
            background: white;
            border-radius: 0;
        }

        .form__group {
            margin-bottom: 28px;
            @include media(md) {
                width: 100%;
                margin: auto;
            }
        }

        .form__item {
            position: relative;
            margin: 0;

            &::after {
                content: '';
                background: url('/assets/img/icons/search.svg');
                position: absolute;
                top: 13px;
                right: 13px;
                width: 22px;
                height: 22px;
            }

            input {
                @include media(md) {
                    border-radius: 2px;
                }
            }
        }
    }

    .tags {
        flex-wrap: wrap;

        .tag {
            position: relative;
            background: colour(teal);
            cursor: pointer;

            img {
                margin-left: 0.5rem;
            }

            input {
                display: none;
            }
        }

        .tag__label {
            flex-basis: 100%;
            color: colour(black);
            padding: 0;
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.25rem;
            text-transform: none;
            margin-right: 0.938rem;
            font-family: $font-main;
        }
    }

    .product__full {
        border: 0;

        &-reviews {
            min-height: 32px;
        }
        @include media(md) {
            display: flex;
            flex-direction: column-reverse;
            border: none;
            margin-bottom: 0.25rem;

            div:nth-child(1) {
                display: none;
            }

            div:nth-child(2) {
                display: none;
            }

            div:nth-child(3) {
                padding: 0.5rem 0;
            }

            div:nth-child(4) {
                text-align: left;
            }
        }
    }
}

#categories {
    .richtext {
        max-width: 640px;
        margin: 0 auto;
    }

    .image__decorated {
        margin-top: 1.5rem;
    }
}
#filters {
    &.loading {
        .filters__loading {
            visibility: visible;
            opacity: 1;
        }

        .form__group {
            opacity: 0.35;
        }
    }
    .filters__loading {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 5.5rem;
        bottom: 4rem;
        left: -8px;
        z-index: 10;
        right: -8px;
    }
}

#product-listing.site-humphrys {

    .sidebar {
        margin-top: 7rem;

        .filters {
            @include media(md) {
                overflow: auto;
                width: 100%;
                max-width: 50%;
            }

            @include media(sm) {
                max-width: 80%;
            }

            &__loading {
                left: 0;
                right: 0;
            }

            h2 {
                padding: 0;
                font-family: $humphrys-font-heading;
                font-weight: 500;
                font-size: 48px;
                line-height: 48px;
                color: black;
                text-transform: unset;
                margin-bottom: 42px;

                @include media(lg) {
                    margin-bottom: 24px;
                    font-size: 36px;
                    line-height: 40px;
                }

                @include media(md) {
                    margin-top: 20px;
                }
            }

            .form {
                &__group {
                    border: 0;
                    padding: 0;
                    margin-bottom: 20px;
                    position: relative;
    
                    h3 {
                        font-weight: 700;
                        text-transform: unset;
                        font-size: 24px;
                        line-height: 28px;
                        margin-bottom: 28px;
                        font-family: $humphrys-font-content;

                        @include media(lg) {
                            font-size: 20px;
                            margin-bottom: 18px;
                        }
                    }
    
                    .badge {
                        margin-top: 0;
                        position: absolute;
                        top: 3px;
                        background: $humphrys-dark-blue;
                        font-family: $humphrys-font-content;
                    }

                    label {
                        font-size: 18px;
                        line-height: 19.8px;
                        font-weight: 700;
                        font-family: $humphrys-font-content;
                        padding: 0;
                        margin-bottom: 15px;

                        @include media(lg) {
                            font-size: 14px;
                            line-height: 16px;
                            margin-bottom: 10px;
                        }

                        @include media(md) {
                            margin-bottom: 15px;
                            line-height: 20px;
                        }

                        @include media(sm) {
                            font-weight: 500;
                        }

                        input {
                            border: 1px solid #736F62;
                            height: 29px;
                            width: 29px;
                            padding: 0;
                            flex: 0 0 29px;
                        }
                    }
                }
            }
        }

        .hero {
            &__simple {
                display: none;
            }
        }
    }
    .tiles {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.25rem 2.5rem;
        margin: 0;

        @include media(nav) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1rem;
            width: 100%;
            margin: auto;
        }

        @media only screen and (max-width: 900px) {
            grid-template-columns: repeat(1, 1fr);
        }

        @include media(md) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media(sm) {
            grid-template-columns: auto;
        }

        .tile {
            display: block;
            margin: 0;

            &.product-card {
                &:hover {
                    a {
                        color: $humphrys-yellow;
                    }
                }
            }

            @include media(md) {
                margin: 0;
            }

            &__content {

                .subtext {
                    display: none;
                }

                h3 {
                    font-family: $humphrys-font-content;
                    font-size: 1.25rem;
                    font-weight: 500;
                    line-height: 24px;
                    text-align: left;
                    text-transform: none;
                    margin: unset;
                    margin-top: 12px;

                    @include media(nav) {
                        font-size: 1.125rem;
                    }

                    @include media(md) {
                        font-size: 1rem;
                        line-height: 20px;
                    }

                    a {
                        color: $plain-text;
                    }
                }
            }

            .product {
                &__full {

                    &-price, &-price-heading {
                        display: flex;

                        @include media(md) {
                            width: 100%;
                        }
                    }
                }

                &__price-value {
                    display: block;
                }
            }
        }
    }

    .tags {
        .tag {
            background: $humphrys-dark-blue;
            font-size: 16px;
            line-height: 16px;
            font-weight: 500;
            font-family: $humphrys-font-content;
            border-radius: 5px;
            padding: 13px 10px;
            text-transform: uppercase;
            align-items: center;
            display: flex;
            justify-content: space-between;

            @include media(lg) {
                font-size: 14px;
                line-height: 16px;
            }

            &__label {
                &.subtext {
                    font-size: 16px;
                    line-height: 16px;
                    font-weight: 400;
                    font-family: $humphrys-font-content;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .search {
        .form {
            &__group {
                margin: 0;

                input {
                    border: 1px solid #736F62;
                    border-radius: 6px;
                    font-family: $humphrys-font-content;
                    font-size: 16px;
                    line-height: 16px;
                    font-weight: 400;
                    color: black;
                    padding: 19px 17px 17px;
                    margin-bottom: 20px;
                }
            }

            &__item {
                &::after {
                    top: 16px;
                    right: 17px;
                }
            }
        }
    }

    .pagination {
        align-items: center;

        &__number {
            font-size: 16px;
            line-height: 16px;
            font-weight: 400;
            font-family: $humphrys-font-content;
            margin-bottom: 0;
        }
    }

    .products {
        .no-results {
            p {
                font-size: 16px;
                line-height: 16px;
                font-weight: 400;
                font-family: $humphrys-font-content;
                color: black;
            }
        }

        &-listing {
            &-wrapper {
                .filter-toggle {
                    max-width: 300px;
                    margin: 0 auto;
                }
            }
        }
    }
}
