.statBar {
    text-align: center;
    margin: 0;
    padding: 81px 0 70px;

    h2 {
        color: colour(teal);
        margin: 0;
        font-size: clamp(50px, 5vw + 1rem, 71px);
        font-weight: 400;
        line-height: 67.45px;
        letter-spacing: 1px;
        text-align: center;
    }

    p {
        font-family: $font-noto;
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
        color: black;
        text-transform: uppercase;
    }
}

//------------------------------------
// Site Humphrys
//------------------------------------

.site-humphrys {
    .statBar {
        padding: 0;

        .columns {
            margin: 0 -20px;

            > * {
                margin: 0 20px;

                @include media(nav) {
                    flex-basis: 50% !important;
                    margin: 20px 0;
                }

                @include media(md) {
                    flex-basis: 100% !important;
                }
            }

            h2 {
                font-size: 60px;
                font-weight: 600;
                line-height: 66px;
                font-family: $humphrys-font-heading;
                color: $humphrys-blue;
                margin-bottom: 1px;
            }

            p {
                font-size: 16px;
                line-height: 17.6px;
                font-weight: 700;
                color: black;
                font-family: $humphrys-font-content;
                margin-bottom: 12px;
            }
        }
    }

    &#about-us {
        .statBar {
            margin-bottom: 0;
        }
    }
}