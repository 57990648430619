/* Utilities */

//------------------------------------
// Display
//------------------------------------
.hidden {
    display: none !important;
}

//------------------------------------
// Hide except for screen readers
//------------------------------------
.sr-only {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.bg {
    &__textured {
        &--warm-white {
            // background: $texture-warm-white center center / 300px 300px repeat;
            background: $texture-cream center center / 300px 300px repeat; // For v2 design
            color: colour(black);
            padding: 4rem 0;
            margin: 0;
        }

        &--teal {
            background: colour(teal);
            color: colour(white);
            padding: 25px;
            margin: 0;
            position: relative;

            h2 {
                color: colour(white);
            }

            .button, .fui-btn {
                color: colour(black);
            }

            .fui-btn-wrapper {
                &.button {
                    &:hover {
                        color: colour(red);
                        background-color: colour(black);

                        .fui-btn {
                            color: colour(red);
                        }
                    }
                }
                .fui-btn {
                    &:hover {
                        color: colour(red);
                    }
                }
            }
        }
    }

    &__flag {
        padding: 4rem 0;
        background: url('/assets/img/accents/bg-flag.png') no-repeat center center / cover;
    }

    &__texture {
        padding: 4rem 0;
        background: url('/assets/img/accents/texture.png') no-repeat center center / cover;
    }

    &__builiding {
        padding: 4rem 0;
        background: url('/assets/img/accents/bg-builiding.png') no-repeat center center / cover;
    }

    &--white {
        background: colour(white);
    }
}

.site-humphrys {
    .bg {
        &__textured {
            &--teal {
                &.quote {
                    background: colour(humphrys-blue-lighter);
                    color: colour(white);
                    padding: 0;
                    margin: 0;
                    position: relative;
                }

                &.bg-dark {
                    background: colour(humphrys-blue-lighter);
                    color: colour(white);
                    padding: 80px 0;

                    h2 {
                        color: colour(white);
                    }
                }
            }

            &--white {
                background: colour(white);
                padding: 80px 0;
            }
        }
    }
}

.bg__textured--teal.bg--decorated {
    background-image: url('/assets/img/icons/bg-arrows.png'), $texture;
    background-size: 288px 360px, cover;
    background-position: right bottom, center center;
    background-repeat: no-repeat;
}

.underline {
    @include colour-line;

    &--white {
        &::after {
            background: url('/assets/img/accents/underline_white.svg') center center no-repeat;
            background-size: 100% 6px;
        }
    }
}

//------------------------------------
// Alignments
//------------------------------------
.text__align {
    &--left {
        text-align: left;
    }

    &--right {
        text-align: right;
    }

    &--center {
        text-align: center;
    }
}

//------------------------------------
// Fancy Image
//------------------------------------
.image__decorated {
    position: relative;
}

.mb {
    &-0 {
        margin-bottom: 0 !important;
    }
    &-1 {
        margin-bottom: 1rem !important;
    }
    &-2 {
        margin-bottom: 2rem !important;
    }
    &-3 {
        margin-bottom: 3rem !important;
    }
    &-4 {
        margin-bottom: 4rem !important;
    }
    &-5 {
        margin-bottom: 5rem !important;
    }
    &-6 {
        margin-bottom: 6rem !important;
    }
}

.mt {
    &-0 {
        margin-top: 0 !important;
    }
    &-1 {
        margin-top: 1rem !important;
    }
    &-2 {
        margin-top: 2rem !important;
    }
    &-3 {
        margin-top: 3rem !important;
    }
    &-4 {
        margin-top: 4rem !important;
    }
}

.space-between {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.align-start {
    align-items: start !important;
}
