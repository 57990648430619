::placeholder {
    color: colour(darkgrey);
    font-family: $font-main;
    font-weight: 500;
}

::selection {
    background-color: colour(black);
    color: colour(white);
}

//------------------------------------
// Base Inputs
//------------------------------------
input:not([type='submit']) {
    font-size: 100%;
}

input:not([type='submit']),
.StripeElement,
select,
textarea {
    border: none;
    padding: 1rem 0.75rem;
    width: 100%;
    background: colour(grey);
    margin: 0.75rem 0;
    margin-right: 1rem;
    font-family: $font-main;
    text-size-adjust: 100%;

    @include media(sm) {
        margin-right: 0;
        margin-bottom: 1rem;
    }
}

.fui-checkbox {
    display: flex;
    align-items: center;

    label {
        margin-left: 1rem;
    }
}

input[type='checkbox'] {
    cursor: pointer;
    appearance: none;
    width: 29px;
    height: 29px;
    background: #eaeaea;
    border-radius: 5px;
    position: relative;
    border: none;

    &:checked {
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            background: url('/assets/img/icons/check-mark.svg') center center no-repeat;
            height: 100%;
            width: 100%;
        }
    }
}

label {
    font-family: $font-alt;
    font-size: 1.125rem;
    line-height: 1.375rem;
    letter-spacing: 2px;
    display: block;
}

//------------------------------------
// Input Errors
//------------------------------------
.has-error {
    outline: 2px solid colour(teal);
}

.errors,
#card-errors {
    color: colour(teal);
    font-family: $font-main;
    font-size: 1rem;
}

#card-errors {
    margin-bottom: 1rem;
}

//------------------------------------
// Input Success
//------------------------------------
.success {
    color: #139619;
    font-family: $font-main;
    font-size: 1rem;
}

//------------------------------------
// Custom Radio
//------------------------------------
.custom-radio {
    position: relative;

    &:focus-within {
        @extend %dashed-focus;
    }

    input.custom-radio__input {
        @extend .sr-only;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 100%;
    }
}

//------------------------------------
// Custom Quantity
//------------------------------------
.quantity-input {
    display: grid;
    grid-template-columns: 2.875rem 1fr 2.875rem;
    align-content: center;
    background-color: colour(grey);

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.2s ease all;
        &:hover {
            background-color: colour(light-grey);
        }
    }

    &__minus {
        padding-left: 1rem;
    }

    &__plus {
        padding-right: 1rem;
    }

    label {
        font-size: 1.125rem;
        font-family: $font-alt;
    }

    input {
        margin: 0;
        padding-left: 0;
        padding-right: 0;
        appearance: none;
        font-size: 1.125rem;
        text-align: center;
        font-weight: 800;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        & {
            -moz-appearance: textfield;
        }
    }
}

//------------------------------------
// Custom Select
//------------------------------------
.custom-select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    position: relative;
    background-color: colour(grey);
    background-image: url('/assets/img/icons/select-arrow.svg');
    background-repeat: no-repeat;
    background-position: 95% center;
    border: none;
    padding: 1rem 2.5rem 1rem 0.75rem;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
    line-height: inherit;
}

//------------------------------------
// Star Input
//------------------------------------
.star-field {
    display: flex;
    margin: 0.5rem 0;

    &:focus-within {
        @extend %dashed-focus;
    }

    > * + * {
        margin-left: 0.5rem;
    }
}

.star-input {
    position: relative;

    input {
        @extend .sr-only;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
    }

    label {
        display: block;
        width: 30px;
        height: 30px;
        background: url('/assets/img/icons/star_full.svg') no-repeat center center / cover;
        cursor: pointer;
    }

    // Checked State
    input:checked ~ label {
        background: url('/assets/img/icons/star_full.svg') no-repeat center center / cover;
    }
}

.star-input[data-active] ~ .star-input {
    label {
        background: url('/assets/img/icons/star_empty.svg') no-repeat center center / cover;
    }
}
