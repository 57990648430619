.imageWithText {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;
    margin: 0 -3em;

    @include media(md) {
        flex-wrap: wrap;
        // padding: 0.5rem 1.5rem;
        margin: 0;
    }

    > * {
        position: relative;
        flex: 1 1 0px;
        flex-basis: calc((100% / 2) - 3em);
        margin: 0 3em;

        @include media(md) {
            flex-basis: 100%;
            margin: 2em 0;
        }
    }

    &__content {
        h2 {
            font-size: clamp(50px, 5vw + 1rem, 71px);
            font-weight: 400;
            line-height: 67.45px;
            letter-spacing: 1px;
            text-align: left;
            margin-bottom: 42px;
            color: colour(teal);
            // overflow-wrap: break-word;
            // hyphens: auto;
        }

        // @include media(md) {
        //     hyphens: manual;
        // }

        // &::after {
        //     content: '\00AD';
        //     display: none;
        // }
    }

    img {
        min-width: 100%;
        filter: drop-shadow(0px 7px 13px rgba(0, 0, 0, 0.15));
    }

    p {
        font-family: $font-main;
        font-size: clamp(12px, 3vw + 1rem, 18px);
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 30px;
    }

    &.image {
        &--left {
            flex-direction: row;

            @include media(md) {
                flex-direction: column-reverse;
            }
        }
        &--right {
            flex-direction: row-reverse;

            @include media(md) {
                flex-direction: column-reverse;
            }
        }
    }
}

//------------------------------------
// Site Humphrys
//------------------------------------

.site-humphrys {
    .section {
        &-imageWithText {
            margin: 80px 0;

            @include media(md) {
                margin: 40px 0;
            }

            .imageWithText {
                padding: 0;

                &__image {
                    width: 100%;
                    height: 100%;
                    flex-basis: calc(50% - 3em);

                    .responsive-image-wrapper {
                        max-height: 100% !important;
                        max-width: 100% !important;

                        img {
                            max-height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                &__content {
                    flex-basis: calc(50% - 3em);

                    h2 {
                        font-family: $humphrys-font-heading;
                        font-size: 36px;
                        line-height: 40px;
                        font-weight: 600;
                        text-transform: unset;
                        text-align: left;
                        color: black;
                        margin-bottom: 40px;
                        letter-spacing: normal;
                    }

                    h4 {
                        font-size: 20px;
                        line-height: 24px;
                        font-family: $humphrys-font-content;
                        margin-bottom: 10px;
                        color: black;
                    }

                    p {
                        font-family: $humphrys-font-poppins;
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 400;

                        a {
                            color: $humphrys-dark-blue;
                            text-decoration: underline;

                            &:hover {
                                color: $humphrys-yellow;
                            }
                        }
                    }
                }

                @include media(md) {

                    &__image {
                        margin: 0;
                    }
                    
                    &__content {
                        margin-bottom: 0;

                        h2 {
                            margin: 16px 0 30px;
                            font-size: 28px;
                            line-height: 28px;
                        }

                        h4 {
                            font-size: 18px;
                            line-height: 24px;
                        }

                        p {
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }

    &:not(#product) {
        .section {
            &-imageWithText {
                margin: 0;
                padding: 112px 0;
            }
        }

        .imageWithText {
            &_stretched {
                @include media(nav) {
                    min-height: 500px;
                    padding: 0;
                    margin: 50px 0;
                }

                @include media(lg) {
                    min-height: 400px;
                }

                .imageWithText {
                    min-height: 700px;
                    align-items: center;

                    @include media(nav) {
                        min-height: 500px;
                    }

                    @include media(lg) {
                        min-height: 400px;
                    }

                    @include media(md) {
                        padding: 0;
                    }

                    &__image {
                        position: unset;
                        flex: 2;

                        @include media(lg) {
                            flex: 1;
                        }

                        @include media(md) {
                            flex: 0 0 100%;
                        }
                    }

                    &__content {
                        flex: 1;
                        @include media(md) {
                            flex: 0 0 100%;
                        }
                    }

                    &.image--right {
                        .responsive-image-wrapper {
                            right: 0;
                            left: 38%;

                            @include media(lg) {
                                left: 50%;
                            }
                        }
                    }
                }

                .container {
                    &-wrapper {
                        position: relative;
                        min-height: 700px;

                        @include media(nav) {
                            min-height: 500px;
                        }

                        @include media(lg) {
                            min-height: 400px;
                        }
                    }

                    min-height: 700px;
                    position: unset;

                    @include media(nav) {
                        min-height: 500px;
                    }

                    @include media(lg) {
                        min-height: 400px;
                    }

                    .responsive-image {
                        width: 100%;

                        &-wrapper {
                            max-width: 100% !important;
                            min-height: 700px !important;
                            position: absolute;
                            left: 0;
                            overflow: hidden;
                            object-fit: contain;
                            right: 38%;
                            top: 0;
                            bottom: 0;
                            height: 100%;
                            margin: auto;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            @include media(nav) {
                                min-height: 500px !important;
                            }

                            @include media(lg) {
                                right: 50%;
                                min-height: 400px !important;
                            }

                            @include media(md) {
                                position: unset;
                                overflow: unset;
                                min-height: unset !important;
                                width: 100% !important;
                                max-width: 100% !important;
                                margin: unset;
                                max-height: unset !important;
                            }
                        }
                    }
                }
            }

            h2 {
                font-family: $humphrys-font-heading;
                font-size: 48px;
                font-weight: 600;
                line-height: 48px;
                color: $plain-text;
                text-transform: none;
                margin-bottom: 16px;

                @include media(md) {
                    font-size: 36px;
                    line-height: 40px;
                }
            }

            .cta__text, p {
                margin-bottom: 32px;
                font-family: $humphrys-font-content;
                font-size: 20px;
                line-height: 28px;

                a {
                    color: $humphrys-dark-blue;
                    text-decoration: underline;

                    &:hover {
                        color: $humphrys-yellow;
                    }
                }
            }

            a {
                &.button {
                    @include media(md) {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}