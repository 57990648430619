.cart-overlay {
    display: none;
    background: rgba(0, 0, 0, 0.85);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.cart {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    max-width: 33rem;
    height: 100%;
    width: 100%;
    background: colour(white);
    padding: 3rem;
    z-index: 100;
    overflow: auto;

    @include media(sm) {
        padding: 2rem;
    }

    .link__simple {
        font-size: 1rem;
        color: colour(teal);
    }

    .quantity-input {
        max-width: 10rem;
    }

    &__header {
        padding-top: 50px;
        position: relative;

        h2 {
            margin: 0;
            font-weight: 400;
        }
    }

    &__close {
        transition: all 0.2s ease;
        position: absolute;
        top: 0;
        right: 0;
        background-image: url('/assets/img/commerce/close-orange.svg');
        height: 40px;
        width: 40px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        &:hover {
            background-image: url('/assets/img/commerce/close-blue.svg');
        }

        img {
            pointer-events: none;
        }
    }

    .text {
        &__align {
            &--right {
                .button {
                    font-family: $font-noto;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0.02em;
                }
            }
        }
    }
}

.site-humphrys {
    .cart {
        &__close {
            background-image: url('/assets/img/commerce/close-humphrys-yellow.svg');

            &:hover {
                background-image: url('/assets/img/commerce/close-humphrys-blue.svg');
            }
        }

        .button {
            @extend .button-humphrys__primary;
        }
    }
}

//------------------------------------
// Open State
//------------------------------------
.cart-is-open {
    .cart,
    .cart-overlay {
        display: block;
    }
}

//------------------------------------
// Line Item
//------------------------------------
.line-item {
    display: grid;
    grid-template-columns: 110px auto min-content;
    grid-template-rows: repeat(3, auto);
    grid-row-gap: 0.5rem;
    grid-column-gap: 1rem;
    justify-content: unset;
    align-items: flex-start;
    margin-top: 2rem;

    &-discount {
        color: colour(teal);
        grid-row: 1;
        grid-column: 3;
        place-self: center;
        font-size: 1rem;
        text-align: right;
        font-weight: 700;
    }

    &-image {
        grid-row: 1/3;
        grid-column: 1;
    }

    &-title {
        grid-row: 1;
        grid-column: 2;

        @include media('sm') {
            grid-row: 2;
            grid-column: 2/-1;
        }

        h3 {
            margin: 0;
        }
    }

    &-remove {
        grid-row: 1;
        grid-column: 3;
        justify-self: end;

        a {
            color: colour(teal);
            &:hover {
                opacity: 0.75;
            }
        }
    }

    p.line-item-options {
        font-size: 0.875rem;
        color: colour(slate-gray);
    }

    &-quantity {
        grid-row: 2;
        grid-column: 2;

        @include media('sm') {
            grid-row: 3;
            grid-column: 1/-1;
        }
    }

    &-total {
        grid-row: 2;
        grid-column: 3;
        place-self: center;
        font-size: 1.125rem;
        text-align: right;
        font-weight: 700;
        list-style: -1px;

        @include media('sm') {
            grid-row: 3;
        }

        &.on-sale {
            .price {
                position: relative;
                color: #e15744;
                opacity: 0.8;

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 100%;
                    height: 2px;
                    background-color: #e15744;
                    transform: translateY(-50%);
                }
            }
        }
    }

    h3 {
        font-size: 1.125rem;
    }
}
