.getQuote {
    a {
        color: colour(black);
        text-decoration: underline;
        font-size: 1.125rem;

        &:hover {
            color: colour(teal);
        }
    }

    .quote {
        &__form {
            flex: 3;

            h2 {
                @extend .h3;
                margin-bottom: 0;
                color: colour(cream);
                mix-blend-mode: unset;
            }

            > div {
                margin-top: 1.875rem;

                button {
                    margin: 0;
                    background-color: transparent;
                    padding: 0;
                    box-shadow: none;
                    text-align: right;
                }
            }

            h2 {
                font-size: clamp(50px, 5vw + 1rem, 75px);
                font-weight: 400;
                line-height: 67.5px;
                text-align: left;
                color: colour(light_cream);
                margin-bottom: 10px;
            }

            span {
                font-family: $font-main;
                font-size: 20px;
                font-weight: 400;
                line-height: 20px;
            }

            .fui {
                &-label {
                    font-family: $font-alt;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 25px;
                    letter-spacing: 1px;
                    text-align: left;
                    text-transform: uppercase;
                    color: white;
                }

                &-input {
                    margin-top: 5px;
                }

                &-btn-wrapper {
                    width: max-content;
                    display: block;
                    margin-left: auto;
                    margin-right: 0;
                }
            }
        }

        &__questions {
            flex: 1;
            padding: 1rem;
            height: fit-content;
            @include media(md) {
                margin-top: 2rem;
            }

            h3 {
                @extend .h4;
                margin-bottom: 1rem;

                span {
                    font-size: clamp(20px, 5vw + 1rem, 30px);
                    font-weight: 400;
                    line-height: 32px;
                    letter-spacing: 1px;
                }
            }

            h4 {
                font-family: $font-handwritten;
                text-transform: uppercase;
                font-size: clamp(20px, 5vw + 1rem, 30px);
                font-weight: 600;
                line-height: 35px;
                letter-spacing: 1px;
                margin-top: 0;
                margin-bottom: 0;
            }

            p,
            a {
                font-family: $font-main;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                color: colour(white);
                text-decoration: none;
            }

            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &__asset {
            flex: 2;
            text-align: right;
            @include media(md) {
                margin-top: 2rem;
            }

            figcaption {
                margin-top: 0.5rem;
            }
        }
    }

    &__content-box {
        border: 2px dashed colour(light_cream);
        z-index: 1;
        position: relative;
        padding-top: 46px;
        padding-bottom: 30px;
    }
}

.quoteHome {
    text-align: center;

    h2 {
        @extend .h1;
        margin-top: 2rem;
    }

    .tiles .tile__heading {
        font-size: 1.125rem;
        font-family: $font-handwritten;
        text-transform: unset;
        font-weight: 400;
        color: colour(teal);
        margin: 0;
    }

    .tiles .tile__subheading {
        font-size: 0.875rem;
        line-height: 1.188rem;
    }

    blockquote {
        background: none;
        font-weight: 400;
        padding: 0;
        margin: 1rem;

        &::after {
            content: none;
        }
    }
}

.site-humphrys {
    .getQuote {
        a {
            &:hover {
                color: $humphrys-yellow;
                text-decoration: none;
            }
        }

        .quote {
            &__form {
                h2 {
                    @extend .h3;
                    margin-bottom: 0;
                    color: colour(white);
                    mix-blend-mode: unset;
                    font-size: 48px;
                    font-weight: 500;
                    line-height: 1;
                    font-family: $humphrys-font-heading;
                    margin-bottom: 20px;
                }

                span {
                    font-family: $humphrys-font-poppins;
                    font-size: 18px;
                }

                .fui {
                    &-label {
                        font-family: $humphrys-font-button;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 1.1;
                        color: colour(white);

                        @media (max-width: 991px) {
                            font-size: 14px;
                        }

                        span {
                            line-height: 15px;
                        }
                    }

                    &-input {
                        font-family: $humphrys-font-button;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 1.15;
                        padding: 20px;
                        margin-bottom: 0;

                        @include media(md) {
                            margin-bottom: 1rem;
                        }
                    }

                    &-row {
                        @include media(md) {
                            margin-top: 0;
                        }
                    }
                }
            }

            &__questions {
                padding: 0;

                h3 {
                    font-family: $humphrys-font-heading;
                    margin-bottom: 2rem;
                    color: white;

                    span {
                        font-size: clamp(20px, 5vw + 1rem, 36px);
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 1.1;
                    }
                }

                h4 {
                    font-family: $humphrys-font-content;
                    text-transform: uppercase;
                    font-size: clamp(18px, 5vw + 1rem, 20px);
                    font-weight: 700;
                    line-height: 22px;
                    letter-spacing: 0;
                    margin-top: 0;
                    margin-bottom: 8px;
                    color: white;
                }

                p {
                    margin-bottom: 32px;
                }
            }

            &__asset {
                figcaption {
                    margin-top: 0.9rem;
                    font-family: $font-main;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    color: colour(white);
                    text-decoration: none;
                }
            }
        }

        &__content-box {
            border: 0;
            padding-top: 80px;
            padding-bottom: 80px;
        }
    }
}
