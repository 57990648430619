.button,
.fui-btn {
    display: inline-block;
    color: colour(black);
    background-color: colour(red);
    font-weight: 700;
    text-align: center;
    font-family: $font-alt;
    text-transform: uppercase;
    position: relative;
    font-size: 0.9rem;
    margin-right: 0;
    line-height: 1.1;
    letter-spacing: 0.05em;
    padding: 4px 10px;
    box-shadow: 3px 3px 0px 0px colour(white);

    &__lg {
        padding: 7px 20px;
        font-family: $font-noto;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.02em;
    }

    &:hover,
    &:focus {
        cursor: pointer;
        color: colour(white);
        background-color: colour(teal);
        &::before {
            width: 15px;
        }
    }

    &.disabled,
    &[disabled] {
        opacity: 0.7;
        pointer-events: none;
    }

    &--wide {
        display: block;
        width: 100%;
    }

    > .button,
    > .fui-btn {
        margin-right: 0;
        &:after,
        &:before {
            display: none;
        }
    }

    &--full {
        background-color: colour(teal);
        color: colour(white);
        padding: 1.25rem 1.875rem;
        width: 100%;
        transition: background-color 0.2s ease-in-out;
        border-radius: 3px;

        &:hover {
            background: black;
            color: white;
        }

        &::before,
        &::after {
            display: none;
        }
    }

    &.pagination__prev {
        margin-left: 0;
        margin-right: 0;
        &:hover,
        &:focus {
            cursor: pointer;
            color: colour(red);

            &::before {
                width: 15px;
            }
        }
    }
}

body {
    .button {
        &__primary {
            font-family: $font-noto;
            background-color: colour(red);
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.05em;
        }
    }
}

body {
    &.site-humphrys {
        button.button,
        a.button {
            text-transform: unset;
            font-family: $humphrys-font-button;
            background-color: transparent;
            color: $humphrys-dark-blue;
            box-shadow: unset;
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;
            padding: 8px 0;
            border-bottom: 1px solid $humphrys-dark-blue;
            display: inline-flex;
            align-items: center;
            position: relative;
            transition: 0.3s ease all;

            &:after {
                content: '';
                height: 12px;
                width: 12px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                background-image: url(/assets/img/icons/icon-arrow-dark-blue.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 12px;
                margin-left: 10px;
                transition: 0.3s ease all;
            }

            &.light,
            &:hover,
            &:focus {
                color: $humphrys-yellow;
                border-color: $humphrys-yellow;
                background: transparent;
                &:after {
                    background-image: url(/assets/img/icons/icon-arrow-yellow.svg);
                }
            }

            &.light {
                &:hover {
                    color: colour(white);
                    border-color: colour(white);
                    &:after {
                        background-image: url(/assets/img/icons/icon-arrow-white.svg);
                    }
                }
            }

            &-humphrys {
                &__primary {
                    display: inline-flex;
                    align-items: center;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 28px;
                    letter-spacing: normal;
                    box-shadow: 0px 1px 3px 0px #0000001a;
                    border-radius: 100px;
                    padding: 12px 16px;
                    transition: 0.3s ease all;
                    text-transform: unset;
                    font-family: $humphrys-font-button;
                    background-color: $humphrys-yellow;
                    color: black;
                    border: 0;

                    &.lg {
                        padding: 16px 24px;
                        font-size: 18px;
                    }

                    &:focus {
                        outline: 2px dashed $humphrys-button-primary-hover;
                        outline-offset: 3px;
                    }

                    &:hover,
                    &:focus {
                        background-color: $humphrys-button-primary-hover;
                        color: black;

                        &.arrow {
                            &:after {
                                background-color: $humphrys-button-secondary-hover;
                            }
                        }
                    }

                    &::after {
                        content: none;
                    }

                    &.arrow {
                        position: relative;

                        &:after {
                            content: '';
                            height: 28px;
                            width: 28px;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            margin-left: 10px;
                            background-color: $humphrys-blue;
                            border-radius: 100px;
                            background-image: url(/assets/img/icons/icon-arrow-white.svg);
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: 12px;
                        }
                    }

                    &.dark {
                        background-color: #040057;
                        color: white;

                        &.arrow {
                            position: relative;

                            &:after {
                                background-color: $humphrys-yellow;
                                background-image: url(/assets/img/icons/icon-arrow-black.svg);
                            }

                            &:hover,
                            &:focus {
                                &:after {
                                    background-color: $humphrys-button-primary-hover;
                                }
                            }
                        }

                        &:hover,
                        &:focus {
                            background-color: $humphrys-blue;
                            color: white;
                        }
                    }
                }

                &__secondary {
                    &.no-arrow {
                        &:after {
                            content: none;
                        }
                    }

                    &.lg {
                        font-size: 18px;
                        padding: 12px 0;

                        &.arrow {
                            position: relative;

                            &:after {
                                height: 16px;
                                width: 16px;
                                background-size: 16px;
                                margin-left: 15px;
                            }
                        }
                    }
                }
            }
        }

        .fui-btn {
            // @extend .button-humphrys__primary;

            &-wrapper {
                background: unset;
                box-shadow: unset;
                text-transform: unset;
                color: unset;
                display: flex;
                justify-content: end;
                background: transparent !important;
                padding: 0;
                margin-top: 28px;

                &:hover {
                    background: unset;
                    box-shadow: unset;
                    color: unset;
                    background: transparent !important;

                    .fui-btn {
                        color: $humphrys-yellow !important;
                    }
                }

                .fui-btn {
                    color: white;
                    background: transparent;
                    box-shadow: unset;
                    border-bottom: 1px solid currentColor;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 28px;
                    text-transform: capitalize;
                    padding: 10px 0;
                    font-family: 'Benton Sans Pro';
                    display: flex;
                    align-items: center;
                    transition: 0.3s ease all;

                    &:hover {
                        color: $humphrys-yellow;
                    }
                }
            }

            &:focus {
                outline-color: white;
            }

            &:after {
                content: '';
                height: 11.2px;
                width: 11.2px;
                display: inline-flex;
                justify-content: end;
                align-items: end;
                margin-left: 10px;
                background-image: url(/assets/img/icons/icon-arrow-white.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 12px;
                transition: 0.3s ease all;
            }

            &:hover {
                &:after {
                    background-image: url(/assets/img/icons/icon-arrow-yellow.svg);
                }
            }
        }
    }
}
