.featured-hero-products {
    display: flex;
    @include media(md) {
        flex-wrap: wrap;
        gap: 1rem;
    }

    > * {
        position: relative;
        display: grid;
        height: 234px;
        width: 100%;
        background: rgba(0, 0, 0, 0.6);
        text-align: center;
        @include media(md) {
            flex-basis: 100%;
        }
    }

    .featured-hero-product {
        overflow: hidden;

        img {
            object-fit: cover;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            z-index: -1;
            filter: grayscale(1);
        }

        &__content {
            color: colour(white);
            margin: auto;
            max-width: 75%;

            span {
                color: colour(teal);
                font-weight: 700;
            }

            .eyebrow {
                letter-spacing: 2px;
                margin-bottom: 0.25rem;
                font-family: $font-alt;
                font-size: 0.75rem;
            }
        }

        &__heading {
            font-family: $font-main;
            font-size: 1.5rem;
        }

        img {
            transition: transform 200ms linear;
        }
    }

    .featured-hero-product:hover {
        img {
            transform: scale(1.05);
        }
    }
}

.site-humphrys {
    .featured-hero-products {
        z-index: 1;
        position: relative;

        @include media(md) {
            margin-top: 20px;
        }

        .featured-hero-product {
            &__content {
                a {
                    display: block;

                    p {
                        font-family: $humphrys-font-content-cond;
                        font-size: 20px;
                        line-height: 1.4;
                        color: colour(white);
                        margin-bottom: 8px;
                    }

                    span {
                        color: $humphrys-yellow;
                    }

                    &:hover span {
                        text-decoration: underline;
                    }
                }
            }

            &__heading {
                font-family: $humphrys-font-heading;
                color: colour(white);
                font-size: 1.5rem;
                line-height: 28px;
                font-weight: 500;
                margin-bottom: 15px;
            }
        }
    }
}
