.slides {
    text-align: center;
    @include media(md) {
        text-align: left;
    }

    h2 {
        position: relative;
        margin: 0 0 4rem 0;
        z-index: 10;
    }

    .slider {
        padding-bottom: 4px;
        max-width: calc(100% - 8rem);
        position: unset;
        @include media(md) {
            overflow: visible;
        }

        .slide {
            display: flex;
            flex-direction: column;
            text-align: center;

            &__content {
                border-radius: 5px;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                overflow: hidden;
            }

            &__title {
                font-family: $font-main;
                font-size: 1.125rem;
                font-weight: 700;
                margin-top: 1rem;
                transition: color 200ms ease;

                a {
                    color: inherit;
                    outline-offset: 0;
                }
            }
        }

        .slide:hover {
            .slide__title {
                color: colour(teal);
            }
        }
    }

    &--simple {
        text-align: center;

        h2 {
            @extend .h1;
        }

        .slider {
            max-width: calc(100% - 8rem);
            position: unset;
            @include media(md) {
                max-width: 100%;
            }
        }

        .slide {
            &__content {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                @include media(md) {
                    flex-wrap: wrap;
                    padding-bottom: 2rem;
                }

                .responsive-image-wrapper {
                    margin: 0 auto;
                }

                > * {
                    // flex: 1;
                    padding: 1rem;
                    flex-basis: calc((100% / 5) - 2rem);
                    margin: 1rem;

                    @include media(md) {
                        flex-basis: calc((100% / 2) - 2rem);
                        margin: 1rem;

                        &:nth-child(3) {
                            flex-basis: 100%;
                        }
                    }
                }
            }
        }

        .next-2,
        .prev-2 {
            background: colour(teal);
            @include media(md) {
                display: none;
            }

            &::after {
                background: url('/assets/img/icons/icon-arrow-black.svg') center center no-repeat;
            }

            &:hover {
                background: colour(theme-blue);
                &::after {
                    background: url('/assets/img/icons/icon-arrow-white.svg') center center no-repeat;
                }
            }
        }
    }
}

.swiper-container {
    list-style: none;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding: 0;
    position: relative;
    z-index: 1;
}

.swiper-button-next,
.swiper-button-prev {
    border-radius: 9999px;
    background: colour(white);
    height: 44px;
    width: 44px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    top: calc(50% + 3rem);

    @include media(md) {
        top: 25px;
    }

    &::after {
        content: '';
        position: absolute;
        background: url('/assets/img/icons/icon-arrow.svg') center center no-repeat;
        background-size: contain;
        bottom: 0;
        right: 14px;
        top: 0;
        left: 14px;
    }

    &:hover {
        background: colour(black);
    }
}
.swiper-button-prev {
    left: 0;
    right: auto;
    &::after {
        transform: rotate(180deg);
    }
    @include media(md) {
        left: auto;
        right: 80px;
    }
}
.swiper-button-next {
    left: auto;
    right: 0;
    @include media(md) {
        left: auto;
        right: 20px;
    }
}

.swiper-container-horizontal>.swiper-pagination-bullets {
    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        margin: 0 8px;
    }
}

.swiper-pagination-bullet-active {
    background: colour(theme-blue);
    height: 16px;
    width: 16px;
    padding: 8px;
}

.swiper-pagination-bullet {
    height: 12px;
    width: 12px;
}

.site-humphrys {
    .bg__textured {
        padding-top: 80px;
        padding-bottom: 80px;
        margin: 0;
        @include media(nav) {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .slides {
        
            h2 {
                position: relative;
                margin: 0;
                z-index: 10;
                font-family: $humphrys-font-heading;
                font-size: 48px;
                line-height: 1;
                font-weight: 600;
                color: #000;
                text-transform: capitalize;
                @include media(md) {
                    font-size: 32px;
                }
            }
    
            .swiper-container {
                margin-left: 0;
            }
    
            .slider {
                max-width: 100%;
            }

            .industry-slider-block {
                margin-bottom: 42px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                flex-wrap: wrap;
                @include media(md) {
                    margin-bottom: 24px;
                }
            }

            .industry-title {
                font-size: 48px;
                line-height: 48px;
                font-weight: 600;
                color: #000;
                margin: 0;
                @media (max-width: 1200px) {
                    font-size: 32px;
                    line-height: 32px;
                }
            }
    
            .industry-slider {
                padding-right: 48px;
                
                &-wrapper {
                    margin-left: calc((100% - 1334px) / 2);
                    padding-left: 0;
                    @media (max-width: 1370px) {
                        margin-left: auto;
                        padding-left: 15px;
                    }
                    @include media(lg) {
                        padding-right: 15px;
                        padding-left: 30px;
                    }
                }
    
                img {
                    width: 100%;
                    height: 100%;
                    max-height: 482px;
                    display: block;
                    max-width: 100%;
                    object-fit: cover;
                }
    
                .responsive-image {
                    position: relative;
                    &::before {
                        content: '';
                        background: linear-gradient(to top, 
                            rgba(0,0,0,0.8) 0%, 
                            rgba(0,0,0,0.4) 30%, 
                            rgba(0,0,0,0) 100%);
                        position: absolute;
                        inset: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
    
                .slide {
                    &__content {
                        position: relative;
                        border-radius: 0;
                        filter: none;
                    }
                    &__title {
                        position: absolute;
                        bottom: 40px;
                        color: #fff;
                        font-size: 24px;
                        line-height: 40px;
                        font-weight: 700;
                        left: 50%;
                        transform: translateX(-50%);
                        margin: 0;
                        font-family: $humphrys-font-content;
                        text-shadow: 
                            2px 2px 4px rgba(0,0,0,0.9),
                            -1px -1px 0 rgba(0,0,0,0.7),
                            1px -1px 0 rgba(0,0,0,0.7),
                            -1px 1px 0 rgba(0,0,0,0.7),
                            1px 1px 0 rgba(0,0,0,0.7);
                        @include media(nav) {
                            font-size: 20px;
                            line-height: 32px;
                        }
                    }
                }
            }
    
            .swiper {
                &-button-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 40px;
                    @media (max-width: 1200px) {
                        gap: 24px;
                    }
                    @include media(md) {
                        gap: 16px;
                    }
                    @media (max-width: 575px) {
                        width: 100%;
                        justify-content: flex-end;
                    }
                }
                &-prev,
                &-next {
                    padding: 20px 22px;
                    background-color: #040057;
                    border-radius: 12px;
                    display: block;
                    line-height: 0;
                    cursor: pointer;
                    transition: all 0.5s linear;
    
                    @include media(md) {
                        padding: 16px 18px;
                    }
                    &:hover {
                        background-color: #060098;
                    }
    
                    svg {
                        display: block;
                    }
                    &.swiper-button-disabled {
                        opacity: 0.6;
                        background-color: #040057;
                    }
                }
            }
        }
    }

    .slides {
        &--simple {
            .next-2,
            .prev-2 {
                &::after {
                    background: url('/assets/img/icons/icon-arrow-white.svg') center center no-repeat;
                }
            }
        }
    }
}