//------------------------------------
// General Product Detail and Layout
//------------------------------------

#product {
    .imageWithText {
        &__image {
            flex-basis: calc(70% - 3em);
            margin-right: 0;

            .responsive-image {
                &__image {
                    max-height: 340px;
                    object-fit: cover;
                }
            }
        }

        &.image--right {
            .imageWithText__image {
                .responsive-image {
                    &__image {
                        object-fit: contain;
                    }
                }
            }
        }

        &__content {
            h2 {
                font-size: 2.813rem;
                font-weight: 400;
                line-height: 2.813rem;
                letter-spacing: 2px;
                text-align: center;
            }

            .button {
                display: none;
            }
        }
    }

    .multiColumn {
        max-width: 969px;

        h2 {
            font-size: 3rem;
            font-weight: 400;
            line-height: 3rem;
            letter-spacing: 2px;
            margin-bottom: 1.25rem;
        }

        h3 {
            font-family: $font-header;
            font-size: 1.875rem;
            font-weight: 400;
            line-height: 2rem;
            letter-spacing: 1px;
            margin-bottom: 1.25rem;
        }
        p {
            font-family: $font-main;
            font-size: 1.125rem;
            font-weight: 400;
            line-height: 1.5rem;
            margin-bottom: 1.25rem;
        }
    }
}

.product-detail {
    margin: 2.5rem 0 0;

    // Layout of product detail sections
    &__inner {
        display: grid;
        grid-template-columns: 1fr 29.75rem;
        grid-template-rows: repeat(3, min-content);
        column-gap: 4rem;
        margin-top: 2.5rem;
        padding-bottom: 3.5rem;
        border-bottom: 1px solid black;

        @include media(lg) {
            grid-template-columns: 1fr;
        }

        .product-detail-images {
            overflow: hidden;
        }

        .product-detail-images,
        .product-info-cards {
            grid-column: 1;

            @include media(lg) {
                grid-column: 1/-1;
            }
        }

        .product-info-cards {
            grid-row: 2;

            @include media(lg) {
                grid-row: 3;
            }
        }

        .product-detail__content {
            grid-row: 1/-1;
            grid-column: 2;

            @include media(lg) {
                grid-column: 1;
                grid-row: 2;
            }
        }
    }

    &__header {
        display: flex;
        flex-direction: column-reverse;
        padding: 0;
        background-color: transparent;

        @include media(lg) {
            margin-top: 1rem;
        }
    }

    &__eyebrow {
        @include line-height-crop(1.6);
        margin-bottom: 0;
        font-size: 1rem;
        letter-spacing: 1px;
        font-family: $font-alt;
    }

    &__heading {
        margin: 1rem 0 0;
        font-size: clamp-sizes(1.875, 3.125);
        color: colour(metflag-blue);
        font-weight: 400;
    }

    &__description {
        margin-top: 0.625rem;
        font-size: 1.125rem;
        line-height: 1.34;

        &.is-truncated {
            position: relative;
            max-height: 6.25rem;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 3rem;
                background-image: linear-gradient(
                    to top,
                    white,
                    #{rgba(255, 255, 255, 0)}
                );
            }
        }
    }

    &__more {
        @extend .plain-link;
        display: inline-block;
        margin-top: 0.875rem;
        color: colour(super-dark-grey);
        text-decoration: underline;
    }

    &__reviews {
        display: flex;
        margin-top: 0.625rem;
        align-items: center;

        .stars img {
            width: 1.25rem;
        }

        .reviews-link {
            margin-left: 1.25rem;
            color: colour(super-dark-grey);
        }
    }
}

//------------------------------------
// Product Images
//------------------------------------
.product-detail-images {
    &__main {
        margin: 0;

        @include media(lg) {
            max-width: 38.75rem;
        }
    }

    &__thumbs {
        margin: 1.25rem 0;
        overflow: hidden;

        .swiper-pagination {
            bottom: 0;
        }

        .swiper-wrapper {
            padding-bottom: 2.1875rem;
            margin: auto;
        }

        .swiper-slide {
            cursor: pointer;
            opacity: 0.7;
            transition: opacity 0.2s linear;
            width: auto;
        }

        .swiper-slide-thumb-active {
            opacity: 1;
        }
    }

    .swiper-slide {
        margin: 0;
    }
}

//------------------------------------
// Product Form
//------------------------------------
.product-form {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    margin-top: 1.563rem;

    .button {
        margin-left: auto;
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.25rem;

        &__item {
            &--full {
                grid-column: 1/-1;
            }
        }
    }

    &__options,
    &__summary {
        padding-top: 1.25rem;
        border-top: 1px solid colour(black);
    }

    &__options {
        padding-top: 1.563rem;
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
    }

    // Form Section
    &__section + &__section {
        margin-top: 1.875rem;
    }

    &__section-heading {
        margin-bottom: 0.625rem;
        font-size: 20px;
        line-height: 25px;
        font-weight: 600;
        font-family: $font-alt;
    }

    &__section-sub {
        font-size: 1rem;
        font-family: $font-main;
        text-transform: none;
        letter-spacing: 0;
        font-weight: 400;

        &::after {
            display: none;
        }
    }

    &__section-info {
        margin-top: 0.5rem;
        font-size: 0.875rem;
        color: colour(super-dark-grey);

        a {
            @extend .plain-link;
            display: block;
        }
    }

    // Total
    &__total {
        display: flex;
        font-size: 1.125rem;
        font-family: $font-alt;
        letter-spacing: 2px;

        .price {
            margin-left: auto;
        }

        .price,
        .sale-price {
            font-size: 1.5rem;
            letter-spacing: 0;
        }

        &.on-sale {
            .price {
                position: relative;
                margin-right: 1rem;
                color: colour(teal);
                opacity: 0.8;

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 100%;
                    height: 2px;
                    background-color: colour(teal);
                    transform: translateY(-50%);
                }
            }
        }
    }

    // Actions
    &__actions {
        display: grid;
        grid-template-columns: 0.8fr 1.2fr;
        grid-template-rows: max-content max-content;
        gap: 1rem;
        margin-top: 1.875rem;

        @include media(lg) {
            grid-template-columns: 1fr 1fr;
        }

        @include media(sm) {
            grid-template-columns: 1fr;
        }

        &-header {
            grid-row: 1;
            grid-column: 1/-1;

            .form__info {
                margin: 0;
            }
        }
    }

    &__section {
        .custom-select {
            background-color: colour(white);
            border: 1px solid colour(dark-gray);
            padding: 14px 40px 14px 20px;
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
        }
    }
}

//------------------------------------
// Request a Quote Form
//------------------------------------
.product-quote-form {
    display: none;
    margin-top: 1.563rem;
    padding-top: 1.563rem;
    border-top: 1px solid black;

    &__submit {
        display: block;
        text-align: right;
    }

    .product-text {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        margin-top: 9px;
    }
}

//------------------------------------
// Submit and Product Form States
//------------------------------------
.product-detail.show-quote-form {
    .product-quote-form {
        display: block;
    }

    .product-form__summary {
        display: none;
    }
}

//------------------------------------
// Box Options
//------------------------------------
.box-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: 1.25rem;

    &__material &__option-label {
        padding-left: 0.5rem;
    }

    &__option {
        position: relative;
        text-align: center;
    }

    &__option-heading {
        font-size: 1.125rem;
    }

    &__option-label {
        padding: 0.93rem 0;
        background-color: colour(white);
        border: 1px solid colour(black);
    }

    &__option-label,
    &__option-info {
        display: block;
        font-family: $font-main;
        font-weight: 400;
    }

    &__option-info {
        margin-top: 0.25rem;
        letter-spacing: 2px;
        font-size: 0.75rem;
    }

    // Checked State
    &__option-input:checked ~ &__option-label {
        background-color: colour(teal);
        color: colour(white);
        border: none;
        box-shadow: 3px 3px 0px 0px colour(white);


        .box-options__option-info,
        .box-options__option-heading {
            color: colour(white);
            font-weight: 800;
        }
    }
}

//------------------------------------
// Swatch Options
//------------------------------------
.swatch-options {
    display: grid;
    grid-template-columns: repeat(auto-fill, 50px);
    column-gap: 1.25rem;
    row-gap: 0.625rem;

    &__label {
        padding: 3px;
        border: 2px solid transparent;
        border-radius: 100%;
        cursor: pointer;

        img {
            border-radius: 100%;
            overflow: hidden;
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    // Toggles
    &.--is-hidden {
        display: none;
    }

    // Checked State
    .swatch-options__option-input:checked + .swatch-options__label {
        border: 2px solid colour(teal);
    }
}

//------------------------------------
// Number Option
//------------------------------------
.number-option {
    input {
        margin: 0;
        font-size: 1.125rem;
        text-align: center;
        font-weight: 800;
    }
}

//------------------------------------
// Info Cards
//------------------------------------
.product-info-cards {
    display: flex;
    margin-top: 20px;

    &__card {
        flex: 1;
    }

    > * + * {
        border-left: 1px solid colour(black);
    }

    @include media(md) {
        display: flex;
        flex-direction: column;

        > * + * {
            border-top: 1px solid colour(grey);
        }

        > * + * {
            border-left: none;
        }
    }
}

//------------------------------------
// Product Highlight
//------------------------------------
.product-highlights {
    min-height: 31.875rem;
    background: url('/assets/img/icons/x.png') no-repeat left center;

    @include media(md) {
        min-height: auto;
        background: none;
    }

    &__inner {
        display: grid;
        grid-template-columns: 1fr minmax(min-content, 1fr);
        gap: 2.5rem;

        @include media(md) {
            grid-template-columns: 1fr;
        }
    }

    &__heading {
        margin-bottom: clamp-sizes(1.25, 2.5, 800);
        padding-top: 2rem;
        line-height: 1;
        letter-spacing: 2px;
    }

    &__image {
        margin: 0;
        align-self: center;

        @include media(md) {
            grid-row: 1;
        }
    }

    &__tiles {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: clamp-sizes(1.25, 2.5);
    }

    &__tile-heading {
        font-size: 1.125rem;
        font-family: $font-main;
        letter-spacing: -1px;
    }
}

//------------------------------------
// Product Tech Specs
//------------------------------------
.product-tech-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px 60px;
    padding: 0 8rem;

    @include media(md) {
        grid-template-columns: 1fr;
        padding: 0;
    }

    &__item {
        padding-top: 1rem;
        border-top: 1px solid #f3f3f3;

        h3 {
            font-size: 1.125rem;
            letter-spacing: -1px;
            font-family: $font-main;
        }
    }
}

//------------------------------------
// Site Humphrys
//------------------------------------

#product.site-humphrys {
    .product {
        &-detail {
            margin: 80px 0 0;

            &__content {
                max-width: 382px;
                width: 100%;

                @include media(nav) {
                    margin: 0 auto;
                    width: 100%;
                    max-width: 100%;
                }
            }
            &__heading {
                font-size: 48px;
                font-weight: 600;
                line-height: 48px;
                font-family: $humphrys-font-heading;
                margin: 0 0 16px 0;
                color: black;
                text-transform: capitalize;

                @include media(md) {
                    font-size: 36px;
                    line-height: 40px;
                }
            }

            &__eyebrow {
                font-family: $humphrys-font-heading;
                font-size: 14px;
                line-height: 16px;
                margin: 0;
                color: $humphrys-dark-blue;
            }

            &__description {
                margin-top: 0;

                p {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                    font-family: $humphrys-font-poppins;
                    color: black;
                }
            }

            &-images {
                &__thumbs {
                    margin-bottom: 0;

                    .swiper {
                        &-wrapper {
                            padding-bottom: 25px;
                        }

                        &-pagination {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &-bullet {
                                height: 12px;
                                width: 12px;
                                margin: 0 5px;
                                padding: 0;
                                transition: 0.3s all;

                                &-active,
                                &:hover {
                                    background: $humphrys-dark-blue;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }

                &__main {
                    @include media(lg) {
                        max-width: 100%;
                    }

                    .swiper {
                        &-slide {
                            &-active {
                                width: 100% !important;
                            }
                        }
                    }
                }
            }
            .breadcrumbs {
                a,
                span,
                .active {
                    font-family: $humphrys-font-content;
                    font-size: 14px;
                    line-height: 16px;
                    font-weight: 700;
                    margin-right: 10px;
                    color: #000;
                    text-transform: uppercase;
                }

                a {
                    color: $humphrys-dark-blue;
                    text-decoration: underline;

                    &:hover {
                        color: $humphrys-yellow;
                    }
                }

                span {
                    color: #736f62;
                }
            }

            &__inner {
                margin-top: 36px;
                grid-template-columns: 1fr 23.875rem;
                padding-bottom: 4.625rem;
                border: 0;

                @include media(lg) {
                    grid-template-columns: 1fr;
                }

                @include media(md) {
                    padding-bottom: 0;
                }
            }
        }

        &-info {
            &-cards {
                margin-top: 30px;
                gap: 29px;

                &__card {
                    border: 0;
                    background: #ededed;
                    border-radius: 6px;
                    align-items: center;
                    justify-content: center;
                    padding: 29px 20px;

                    .icon-card {
                        &__icon {
                            margin-bottom: 16px;

                            @include media(sm) {
                                margin-bottom: 0;
                            }
                        }

                        &__heading {
                            margin-top: 0;
                            margin-bottom: 4px;
                            font-size: 16px;
                            line-height: 17.6px;
                            font-family: $humphrys-font-content;
                            color: black;
                            font-weight: 700;
                        }

                        &__copy {
                            margin: 0;
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 400;
                            font-family: $humphrys-font-poppins;
                        }

                        &__cta {
                            display: none;
                        }
                    }
                }
            }
        }

        &-form {
            margin: 0;
            gap: 0;

            &__options {
                margin-top: 20px;
                padding: 0;
                border-top: 1px solid #ededed;
                gap: 0;
            }

            &__section {
                &-heading {
                    font-size: 16px;
                    line-height: 18px;
                    text-transform: uppercase;
                    font-family: $humphrys-font-content;
                    font-weight: 600;
                    margin-bottom: 11px;
                    margin-top: 20px;
                }

                .custom-select {
                    width: 100%;
                    text-align: center;
                    padding: 15px 0 15px 16px;
                    appearance: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    outline: none;
                    background-color: colour(white);
                    font-size: 18px;
                    line-height: 20px;
                    font-weight: 700;
                    text-align: left;
                    border: 1px solid #c0c0c0;

                    &:hover {
                        background-color: #d9d9d9;
                    }

                    &:focus {
                        outline: 2px dashed $humphrys-blue;
                        outline-offset: 3px;
                    }
                }
            }

            &__grid__item {
                .box-options {
                    display: grid;
                    grid-template-columns: 1fr;
                    column-gap: 21px;
                    row-gap: 16px;

                    @include media(lg) {
                        grid-template-columns: 1fr 1fr;
                    }

                    @include media(md) {
                        grid-template-columns: 1fr;
                    }

                    &__option {
                        &-info {
                            margin-top: 0;
                        }
                        &-label {
                            text-align: center;
                            cursor: pointer;
                            padding: 10px 5px;
                            font-size: 18px;
                            line-height: 20px;
                            font-weight: 700;
                            width: 100%;
                            font-family: $humphrys-font-content;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            span {
                                font-size: 18px;
                                line-height: 20px;
                                font-weight: 700;
                                font-family: $humphrys-font-content;
                            }
                        }
                        &-input {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .custom-radio {
                        width: 100%;
                        text-align: center;

                        label {
                            padding: 0;
                            justify-content: stretch;
                            align-items: stretch;

                            span {
                                background-color: colour(white);
                                color: #000000;
                                font-weight: 700;
                                padding: 10px 5px;
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 1px solid #c0c0c0;
                            }
                        }

                        &__input {
                            &:checked,
                            &:hover {
                                background-color: $humphrys-dark-blue;
                                color: colour(white);
                                font-weight: 700;

                                & ~ span {
                                    background-color: $humphrys-dark-blue;
                                    color: colour(white);
                                    border-color: $humphrys-dark-blue;
                                }
                            }
                        }
                    }
                }
            }
            &__summary {
                margin-top: 20px;
                border-top: 1px solid #ededed;
            }

            &__total {
                display: flex;
                font-family: $humphrys-font-content;

                span {
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 700;
                    text-transform: uppercase;
                }

                .price {
                    margin-left: auto;
                    font-size: 18px;
                    line-height: 20px;
                    font-weight: 700;
                }
            }

            &__actions {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                &-header {
                    label {
                        font-size: 16px;
                        line-height: 18px;
                        font-weight: 700;
                        font-family: $humphrys-font-content;
                        text-transform: uppercase;
                    }
                }

                .quantity {
                    &-input {
                        background-color: white;
                        grid-template-columns: 52px 52px 52px;
                        border: 1px solid #c0c0c0;

                        input[type='number'] {
                            text-align: center;
                            border: none;
                            outline: none;
                            background-color: white;
                            font-size: 16px;
                            padding: 2px;
                        }

                        &-container {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            gap: 10px;
                            width: 100%;
                        }

                        &__button {
                            background: none;
                            border: none;
                            font-size: 20px;
                            cursor: pointer;
                            padding: 0 5px;
                            display: flex;
                            align-items: center;
                            height: 52px;
                        }
                    }
                }

                .button {
                    width: 100%;
                    justify-content: center;
                }
            }

            &__whole-section {
                display: flex;
                flex-direction: row;
                column-gap: 20px;
                margin-top: 20px;

                p {
                    max-width: 230px;
                    width: 100%;
                    font-size: 14px;
                    color: #000000;
                    line-height: 20px;
                    margin: 0;
                    font-weight: 400;
                    font-family: $humphrys-font-poppins;
                }

                a {
                    font-size: 14px;
                    color: #000000;
                    line-height: 20px;
                    text-align: center;
                    font-weight: 400;
                    font-family: $humphrys-font-poppins;
                    text-decoration: underline;
                }
            }
        }

        &-quote-form {
            border-top: 1px solid #ededed;
        }
    }

    .section {
        &-imageWithText {
            .imageWithText {
                &.image--right {
                    .imageWithText__image {
                        margin-right: 3em;
                        margin-left: 0;

                        @include media(md) {
                            margin-right: 0;
                        }
                    }
                }

                &__image {
                    width: 100%;
                    height: 100%;
                    flex-basis: calc(50% - 3em);

                    .responsive-image-wrapper {
                        max-height: 100% !important;
                        max-width: 100% !important;

                        img {
                            max-height: 100%;
                        }
                    }
                }

                &__content {
                    h2 {
                        font-size: 36px;
                        line-height: 40px;
                        font-weight: 600;
                        text-transform: unset;
                        text-align: left;
                        color: black;
                        margin-bottom: 44px;
                        letter-spacing: normal;
                    }
                }

                @include media(md) {
                    &__image {
                        margin: 0;
                    }

                    &__content {
                        margin-bottom: 0;

                        h2 {
                            margin: 16px 0 30px;
                            font-size: 28px;
                            line-height: 28px;
                        }

                        h4 {
                            font-size: 18px;
                            line-height: 24px;
                        }

                        p {
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }

    .multiColumn {
        max-width: 1370px;

        .columns {
            margin: 0 -3rem;

            h3 {
                font-family: $humphrys-font-heading;
                font-size: 36px;
                line-height: 40px;
                font-weight: 600;
                text-transform: unset;
                text-align: left;
                color: black;
                margin-bottom: 40px;
            }

            h4 {
                font-size: 20px;
                line-height: 24px;
                font-family: $humphrys-font-content;
                margin-bottom: 10px;
                color: black;
            }

            p {
                font-family: $humphrys-font-poppins;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;

                a {
                    color: $humphrys-dark-blue;
                    text-decoration: underline;

                    &:hover {
                        color: $humphrys-yellow;
                    }
                }
            }

            @include media(md) {
                margin: 0;

                h3 {
                    margin: 16px 0 30px;
                    font-size: 28px;
                    line-height: 28px;
                }

                h4 {
                    font-size: 18px;
                    line-height: 24px;
                }

                p {
                    margin-bottom: 15px;
                }
            }

            > * {
                margin: 0 3rem;

                @include media(md) {
                    margin: 0;
                }
            }
        }
    }
}
