.imageTiles {
    h3 {
        font-family: $font-main;
        font-size: 1.5rem;
        text-transform: unset;
    }

    .tile {
        &__content {
            font-size: 1rem;
            line-height: 1.25rem;
            padding: 1rem 0;
        }
    }
}

.image-tiles {
    .imageTiles {
        .tiles {
            &__headline {
                display: flex;
                flex-direction: row;
                margin-bottom: 43px;

                h2 { 
                    width: 65%;
                    font-size: clamp(50px, 5vw + 1rem, 71px);
                    font-weight: 400;
                    line-height: 67.45px;
                    letter-spacing: 1px;
                    color: white;
                }

                p {
                    width: 35%;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    color: white;
                    text-align: right;
                    font-family: $font-main;
                }
            }

            .tile {
                &__content {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                    font-family: $font-main;
                    color: white;
                    padding-top: 12px;
                    padding-bottom: 0;
                    margin-right: 80px;
                }
            }
        }
    }
}