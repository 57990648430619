.columns {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4rem;

    @include media(md) {
        margin: 0 !important;
    }

    > * {
        flex: 1 1 0;
        margin: 0 4rem;

        @include media(md) {
            flex-basis: 100% !important;
            margin: 1rem 0;
        }
    }

    ul {
        font-size: 1.125rem;
        margin-bottom: 1rem;
        padding-left: 2rem;

        li {
            display: list-item;
            padding: 0.5rem 0;
        }
    }

    &__two {
        > * {
            flex-basis: calc((100% / 2) - 8rem);
        }
    }

    &__three {
        margin: 0 -2rem;

        > * {
            flex-basis: calc((100% / 3) - 4rem);
            margin: 0 2rem;
        }
    }

    &__fluid {
        align-items: center;
        margin: 0 -2.5rem;

        > * {
            flex-basis: unset;
            margin: 0 2.5rem;
            max-width: fit-content;

            &:nth-child(2n) {
                flex: 1 1 0;
            }
        }
    }
}
