.responsive-image {
    display: block;
    position: relative;
    margin: 0;

    img {
        display: block;
        width: 100%;
        height: 100%;
        &.lazyload {
            opacity: 0;
            transition: opacity 200ms linear;
        }
        &.lazyloaded {
            opacity: 1;
        }
    }
}

.responsive-bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
}
