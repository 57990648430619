.discounts {
    margin-bottom: 2rem;

    &__input {
        display: flex;
        align-items: center;
        width: 100%;

        input {
            margin-right: 0.75em;
            border-width: 1.5px;
            border-style: solid;
            border-color: colour(slate-gray);
            &::placeholder {
                color: colour(slate-gray);
            }
        }
    }

    h2.discounts__heading {
        font-size: 1.125rem;
    }

    ul, li {
        list-style: disc;
    }
}
